<template>
  <div class="login">
      <div class="login_content">
          <img class="login_left" src="../../assets/login/aaa.jpg" alt="">   
          <div class="login_right">
              <div class="login_title">速修美(用户端)</div>
              <div class="login_title_name">更方便快捷</div>
              <div class="login_acc">
                    <img src="../../assets/login/account.png" alt="" >
                    <input type="text" placeholder="请输入登录账号" v-model="account">
              </div>
              <div class="login_psd">
                    <img src="../../assets/login/psd.png" alt="" >
                    <input type="password" placeholder="请输入登录密码" v-model="password">
              </div>
			  <div style="width: 100%;height: 10px;"></div>
			  
				<div class="login_yonghu">
					<img src="../../assets/login/ju.png" v-if="Check == 0" alt="" @click="CheckBtn(1)">
					<img src="../../assets/login/ju-1.png" v-if="Check == 1" alt="" @click="CheckBtn(0)">
					<div style="display: flex;">我已阅读同意<div style="color: #1A72FE; cursor:pointer " @click="xieyi()">《合作协议》</div><div style="color: #1A72FE; cursor:pointer "  @click="xieyi()">《入住协议》</div></div>
				</div>
			  
              <div class="login_yonghu">
                  <img src="../../assets/login/ju.png" v-if="remember == 0" alt="" @click="rememberaccount(1)">
                  <img src="../../assets/login/ju-1.png" v-if="remember == 1" alt="" @click="rememberaccount(0)">
                  <div>记住用户名</div>
              </div>
              <div class="login_btn" @click="login">登录</div>
              <div class="reg" @click="goregister">注册→</div>
          </div>
      </div>
  </div>
</template>

<script>
import { Login } from '../../api/request';
import { setLocal,getLocal } from "../../libs/util";
export default {
    name:'Login',
    data(){
        return{
            remember:0,//判断有无记住用户名
            account:'',//账号
            password:'',//密码 
			
			Check:0, //判断是否同意协议
			
        }
    },
    created(){
        console.log(localStorage.getItem("account"))
        if(localStorage.getItem("account") != null){
            this.account = localStorage.getItem("account")
            this.remember = 1
        }        
    },
    methods:{
		
		xieyi(){ //协议
			this.$router.push('/UserAgreement')
		},
		
		CheckBtn(index){
			this.Check = index;
		},
		
        rememberaccount(type){
            this.remember = type
        },
        //登录
        login(){
			
			
			if(this.Check == 0){
			    this.$message({
			        message:'请先同意合作协议',
			        type:'error',
			        duration:1000
			    })
			    return
			}
			
            if(this.account == ""){
                this.$message({
                    message:'请输入登录账号',
                    type:'error',
                    duration:1000
                })
                return
            }
            if(this.password == ""){
                this.$message({
                    message:'请输入登录密码',
                    type:'error',
                    duration:1000
                })
                return
            }
            var params = {
                phone:this.account,
                password:this.password
            }
            console.log(params)
            Login(params).then(res=>{
                console.log(res)
                if(res.data.code == 200){
                    if (res.data.userType=='master'){
                        this.$message('账号密码错误.');
                        return;
                    }
                    this.$message('登录成功');
                    if(this.remember == 1){
                        localStorage.setItem("account",this.account)
						
                        localStorage.setItem("id",res.data.data.id)
	
                        localStorage.setItem("msg",JSON.stringify(res.data.data));
						
						localStorage.setItem("banlance",JSON.stringify(res.data.data.banlance));
						
						this.$router.push('/Home')
						
                    }else{
						
						localStorage.setItem("id",res.data.data.id)
						localStorage.setItem("msg",JSON.stringify(res.data.data))
                        localStorage.removeItem("account")
						
						this.$router.push('/Home')
                    }
					
                    
                }else{
                    this.$message(res.data.data);
                }
            })
        },
        //进入注册页面
        goregister(){
            this.$router.push('/Register')
        }
    }
}
</script>

<style scoped>
.login{ 
  width: 100%;
  height: 100%;
  background: url(../../assets/login/bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_content{
  width: 1050px;
  height: 672px;
  background-color: #FFFFFF;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.login_left{
    width: 450px;
    height: 560px;
}
.login_right{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.login_title{
    font-size: 44px;
    font-weight: bold;
}
.login_title_name{
    margin-top: 18px;
    font-size: 18px;
    color: #A9A9A9;
}
.login_acc{
    margin-top: 83px;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #EEEEEE;
}
.login_acc img{
    margin-right: 20px;
    width: 28px;
    height: 30px;
}
.login_acc input{
    font-size: 20px;
}
.login_psd{
    margin-top: 40px;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #EEEEEE;
}
.login_psd img{
    margin-right: 20px;
    width: 28px;
    height: 30px;
}
.login_psd input{
    font-size: 20px;
}
.login_yonghu{
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.login_yonghu img{
    margin-right: 20px;
    width: 20px;
    height: 20px;
}
.login_yonghu div{
    font-size: 18px;
    color: #919191;
}
.login_btn{
    margin-top: 40px;
    width: 320px;
    height: 66px;
    background-color: #1A72FE;
    font-size: 26px;
    text-align: center;
    line-height: 66px;
    border-radius: 33px;
    color: #FFFFFF;
    cursor: pointer;
}
.reg{
    margin: 34px auto 0px;
    font-size: 20px;
    color: #4A9BEC;
    cursor: pointer;
}


</style>