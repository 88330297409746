import {post} from '@/api/common';
// 注册
export const Register = (data) => {
    return post('/repairOrder/register', data);
};
//登录
export const Login = (data) => {
    return post('/repairOrder/login', data);
};
//发送验证码
export const SendCode = (data) => {
    return post('/repairOrder/sendCode', data);
};
//获取故障类型
export const GetFaultTypes = (data) => {
    return post('/app/getFaultTypes', data);
};
//查询师傅
export const FindMaster = (data) => {
    return post('/repairOrder/findMaster', data);
};
//开票记录
export const RecordsOfMakeOutAnInvoice = (data) => {
    return post('/repairOrder/recordsOfMakeOutAnInvoice', data);
};
//充值价格
export const PrepaidPhonePrices = (data) => {
    return post('/repairOrder/prepaidPhonePrices', data);
};
//充值记录
export const TransactionList = (data) => {
    return post('/repairOrder/transactionList', data);
};
//查询银行卡支付信息
export const PayMessage = (data) => { 
    return post('/repairOrder/payMessage', data);
};
//修改企业信息
export const EditEnterpriseCustomersById = (data) => { 
    return post('/repairOrder/editEnterpriseCustomersById', data);
};
//查询未开票订单
export const UninvoicedWorkOrder = (data) => { 
    return post('/repairOrder/uninvoicedWorkOrder', data);
};
//获取品牌
export const GetAllBrand = (data) => { 
    return post('/repairOrder/getAllBrand', data);
};
//开票申请
export const ConfirmMakeOutAnInvoice = (data) => { 
    return post('/repairOrder/confirmMakeOutAnInvoice', data);
};
//获取我的工单
export const MyWorkOrder = (data) => { 
    return post('/repairOrder/myWorkOrder', data);
};
//取消工单
export const CancelWorkOrder = (data) => { 
    return post('/repairOrder/cancelWorkOrder', data);
};
//获取保险信息
export const Insurance = (data) => { 
    return post('/repairOrder/insurance', data);
};
//发布工单
export const ReleaseWorkOrder = (data) => { 
    return post('/repairOrder/releaseWorkOrder', data);
};
//评价评分
export const Score = (data) => { 
    return post('/repairOrder/score', data);
};
//选择接单师傅
export const ChooseMaster = (data) => { 
    return post('/repairOrder/chooseMaster', data);
};
//拒绝验收
export const ToRefuseAcceptance = (data) => { 
    return post('/repairOrder/toRefuseAcceptance', data);
};
//同意验收
export const ToDetermineTheAcceptance = (data) => { 
    return post('/repairOrder/toDetermineTheAcceptance', data);
};
//获取所有门店
export const GetStoreByBrandId = (data) => { 
    return post('/repairOrder/getStoreByBrandId', data);
};
//获取客服电话
export const GetCustomerService = (data) => { 
    return post('/master/getCustomerService', data);
};

// 获取微信付款接口
export const PrepaidPhonePricesw = (data) => {

    return post('/repairOrder/wxPrepaidPhonePrices', data);
};
// 获取用户openid
export const  getOpenId = (data) => {
    return post('/repairOrder/getOpenId', data);
};
// 获取用户提现接口
export const  userWithdrawal = (data) => {
    return post('/repairOrder/withdrawal', data);
};

// 用户协议
export const xieyi = (data) => {
	
    return post('/repairOrder/getAgreement', data);
};

// 修改密码
export const editPassword = (data) => {
	
    return post('/repairOrder/editPassword', data);
};

// 更新金额
export const getRepairOrderById = (data) => {
	
    return post('/repairOrder/getRepairOrderById', data);
};

// 立即发布
export const immediatelyRelease = (data) => {
	
    return post('/repairOrder/immediatelyRelease', data);
};