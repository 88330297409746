<template>
  <div class="home">
    <!-- 头部 -->
    <div class="header">
      <div class="header_left">
        <img src="../../assets/home/icon_bg.png" alt="" />
        <div>速修美(用户端)</div>
      </div>
      <div class="header_right">
        <div class="header_right_one">
          <img class="header_right_one_1" v-if="imageUrl==''" src="../../assets/home/icon_bg.png" alt="" />
          <img class="header_right_one_1" v-if="imageUrl!=''" :src="imageUrl" alt="" />
          <div class="header_right_one_2">{{companyname}}</div>
          <div class="quit" @click="quit">退出登录</div>
        </div>
        <div class="header_right_three"></div>
       <div class="header_right_two ">
          <div>
			  <div>余额：{{banlance}}元 <i class="el-icon-refresh" style="color:green" @click="refreshBanlance()"></i></div>
			  <div>余额提现请使用APP</div>
		  </div>
          <!-- <img src="../../assets/home/icon_1.png" alt="" /> -->
        </div>
      </div>
    </div>
    <!-- 头部 -->

    <div class="home_content">
        <!-- 左侧内容 -->
        <div class="left_list">
            <div class="lists" :class="status == 1?'select_lists':''" @click="selectlist(1)">
                <div class="lists_left"><img class="img_1" src="../../assets/home/fabu.png" alt=""></div>
                <div class="lists_right">发布工单</div>
                <div class="lists_select" v-show="status == 1"><img src="../../assets/home/jian.png" alt=""></div>
            </div>
            <div class="lists" :class="status == 2?'select_lists':''" @click="selectlist(2)">
                <div class="lists_left"><img class="img_1" src="../../assets/home/gongdan.png" alt=""></div>
                <div class="lists_right">我的工单</div>
                <div class="lists_select" v-show="status == 2"><img src="../../assets/home/jian.png" alt=""></div>
            </div>
            <div class="lists" :class="status == 3?'select_lists':''" @click="selectlist(3)">
                <div class="lists_left"><img class="img_1" src="../../assets/home/shifu.png" alt=""></div>
                <div class="lists_right">找师傅</div>
                <div class="lists_select" v-show="status == 3"><img src="../../assets/home/jian.png" alt=""></div> 
            </div>
            <div class="lists" :class="status == 4?'select_lists':''" @click="selectlist(4)">
                <div class="lists_left"><img class="img_1" src="../../assets/home/chongzhi.png" alt=""></div>
                <div class="lists_right">立即充值</div>
                <div class="lists_select" v-show="status == 4"><img src="../../assets/home/jian.png" alt=""></div>
            </div>
            <div class="lists" :class="status == 5?'select_lists':''" @click="selectlist(5)">
                <div class="lists_left"><img class="img_1" src="../../assets/home/fapiao.png" alt=""></div>
                <div class="lists_right">发票管理</div>
                <div class="lists_select" v-show="status == 5"><img src="../../assets/home/jian.png" alt=""></div>
            </div>
            <div class="lists" :class="status == 6?'select_lists':''" @click="selectlist(6)">
                <div class="lists_left"><img class="img_1" src="../../assets/home/geren.png" alt=""></div>
                <div class="lists_right">个人信息</div>
                <div class="lists_select" v-show="status == 6"><img src="../../assets/home/jian.png" alt=""></div>
            </div>
        </div>
        <!-- 左侧内容 -->
        <div class="release">
          <router-view/>
        </div>
    </div>
    
    <div class="foot">
        备案号:
        <a style="color:#666" target="_blank" rel="noopener" href="http://beian.miit.gov.cn/"> 沪ICP备17014414号-2 </a>
    </div>
  </div>
</template>

<script>
import eventBus from '../../api/goBackEntity.js';
import {imghost,utf8Decode} from '../../api/common';
import { Login } from '../../api/request';
import mqtt from 'mqtt';
 // 配置mqtt
  var client = null;
  var now=new Date();
  const options = {
    connectTimeout: 40000,
    clientId: 'jsclient-'+now.getHours()+now.getMinutes()+now.getSeconds()+now.getMilliseconds(), 
    username: 'admin',
    password: 'password',
    clean: true
  }

export default {
  name: "Home",
  data() {
    return {
        status:1,
        msg:'',
        imageUrl:'',
        imgBaseUrl:imghost,
        companyname:'普通用户',
		    banlance:0,
        mqttUrl:'ws://139.196.53.76:61623',
        topic:'jsclient/topic',
    };
  },
  created(){
    this.getmsg();
    if(this.$router.history.current.name == 'Release'){
      this.status = 1
    }
    if(this.$router.history.current.name == 'Myworkorder' || this.$router.history.current.name == 'Orderdetails'){
      this.status = 2
    }
    if(this.$router.history.current.name == 'Teacher' || this.$router.history.current.name == 'Details'){
      this.status = 3
    }
    if(this.$router.history.current.name == 'Rightrecharge'){
      this.status = 4
    }
    if(this.$router.history.current.name == 'Myself'){
      this.status = 6
    }
    if(this.$router.history.current.name == 'Invoice' || this.$router.history.current.name == 'Apply' || this.$router.history.current.name == 'Information' 
    || this.$router.history.current.name == 'Invoicerecord' || this.$router.history.current.name == 'Invoicedetails'){
      this.status = 5
    }
    var that = this
    eventBus.$on('mm', function(data){
      // console.log("123456")
      that.getmsg()
    }.bind(this));
  },
  mounted(){
    this.mqttMsg()
  },
  watch:{
    $route(to,from){
      if(to.path==="/Teacher"){
        this.status = 3
      }
      if(to.path==="/Myworkorder"){
        this.status = 2
      }
      if(to.path==="/Release"){
        this.status = 1
      }
    },
  },
  destroyed() {
    if(client) {
      client.end() //离开页面的时候  关闭mqtt连接
      client = null
    }
  },
  methods: {
    mqttMsg () {
      if (!client) {
        client = mqtt.connect(this.mqttUrl, options)
      }
      // mqtt连接
      client.on('connect', (e) => {
        console.log(e, "MQTT连接成功！！！")
        client.subscribe(this.topic, { qos: 2 }, (error) => {  // qos 为通道
          if (!error) {
            console.log('消息订阅成功')
          } else {
            console.log('消息订阅失败')
          }
        })
      })
      // 接收消息处理
      client.on('message', (topic, message) => {
        let receiveMsg=JSON.parse(message.toString());
        console.log('收到来自', topic, '的消息---->>', receiveMsg)
        if (receiveMsg.platform<0){
          //this.$alert(receiveMsg.content)
          this.$message({
                    dangerouslyUseHTMLString:true,
                    message:"<p align='center'>--=="+receiveMsg.title+"==--</p><p>"+receiveMsg.content+"</p>",
                    type:'warning',
                    center:true,
                    showClose:true,
                    duration: 0
                })
        }
      })
      // 断开发起重连
      client.on('reconnect', (error) => {
        console.log('正在重连:', error)
      })
      // 链接异常处理
      client.on('error', (error) => {
        console.log('连接失败:', error)
      })
    },
    getmsg(){
      if(localStorage.getItem("msg")){
        this.msg = JSON.parse(localStorage.getItem("msg"))
		    this.banlance = JSON.parse(localStorage.getItem("banlance"))
        if(this.msg.headPic !=null && this.msg.headPic !=''){
            this.imageUrl = this.imgBaseUrl+this.msg.headPic
        }
        if(this.msg.companyName!=null && this.msg.companyName!=''){
          this.companyname = this.msg.companyName
        }else{
          this.companyname=this.msg.phone;
        }
        //if(this.msg.contactPersonnel !=null && this.msg.contactPersonnel !=''){
        //  this.name = this.msg.contactPersonnel
        //}else{
        //  this.name='游客'
        //}
      }
    },
    //刷新余额
    refreshBanlance(){
      var params = {
          phone:this.msg.phone,
          password:this.msg.password
      }
      let that=this;
      Login(params).then(res=>{
          if(res.data.code == 200){
            that.banlance= res.data.data.banlance;
            that.msg.banlance= res.data.data.banlance;
          }
      });
    },
    quit(){
      var account=localStorage.getItem("account")
      // console.log(account)
      setTimeout((res)=>{
        localStorage.clear()
        localStorage.setItem("account",account)
        this.$router.replace('/')
        location.reload();
      },500)
    },
    //   切换状态栏
      selectlist(index){
        if(this.status == index){
          return
        }
        if(index == 1){

          this.$router.push('/Release')
        }
        if(index == 2){

          this.$router.push('/Myworkorder')
        }
        if(index == 3){
          this.$router.push('/Teacher')
        }
        if(index == 4){

          this.$router.push('/Rightrecharge')
        }   
        if(index == 5){

          this.$router.push('/Invoice')
        }
        if(index == 6){

          this.$router.push('/Myself')
        }
        this.status = index
      }
  },
};
</script>

<style scoped> 
html,body{height:100%; margin:0;}
.home{
    width: 100%;
    height: 100%;
}
.header{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 80px;
    display: flex;
    z-index: 999;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #CCCCCC;
    background-color: #FFFFFF;
}
.header_left{
    display: flex;
    align-items: center;
}
.header_left img{
    margin-left: 57px;
    width: 92px;
    height: 50px;
}
.header_left div{
    margin-left: 20px;
    font-size: 16px;
}
.header_right{
    display: flex;
    align-items: center;
    
}
.header_right_one{
    height: 80px; 
    position: relative;
    padding: 0px 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.header_right_one_1{
    width: 34px;
    height: 34px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}
.header_right_one_2{
    margin-top: 8px;
    font-size: 14px;
    cursor: pointer;
}
.quit{
  cursor: pointer;
  display: none;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 80px;
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  height: 40px;
  line-height: 40px;
  background-color: #FFFFFF;
}
.header_right_one:hover .quit{
  display: block;
}
.header_right_two{
    padding: 0px 43px;
    display: flex;
    align-items: center;
}
.header_right_two div{
    font-size: 14px;
    color: #4A9BEC;
}
.header_right_two img{
    margin-left: 8px;
    width: 10px;
    height: 5px;
}
.header_right_three{
    width: 1px;
    height: 55px;
    background-color: #DBDBDB;
}
.home_content{
    padding-top: 80px;
    height: 97%;
    box-sizing: border-box;
    display: flex;
    
}
.left_list{
    width: 15%;
    height: 100%;
    background-color: #20284A;
    box-sizing: border-box;
    padding-top: 28px;
}
.lists{
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 58px;
    height: 75px;
    cursor: pointer;
}
.select_lists{
    /* position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 58px;
    height: 75px;
    cursor: pointer; */
    background-color: #35406E;
}
.lists_left{
    width: 30px;
}
.img_1{
    width: 27px;
    height: 30px;
}
.lists_right{
    margin-left: 36px;
    font-size: 18px;
    color: #FFFFFF;
}
.lists_select{
    position: absolute;
    top: 0px;
    right: 0px;
    height: 75px;
    display: flex;
    align-items: center;
}
.lists_select img{
    width: 11px;
    height: 19px;
}
.release{
    overflow-y: scroll;
    width: 85%;
}
.foot{
  background-color:#eee;
  bottom:0;
  width:100%;
  height:30px;
  line-height:30px;
  text-align:center;
}
</style>