<template>
  <div class="workorder">
      <!-- 搜索内容 -->
      <el-form ref="form" :model="form" label-width="80px">
        <div class="form_fle">
            <el-form-item label="订单编号" style="margin-right: 20px">
              <el-input v-model="form.num" placeholder="填写订单编号"></el-input>
            </el-form-item>
            <el-form-item label="上门地址" style="margin-right: 20px">
              <div class="shangmen">
                <el-cascader
                  size="large"
                  :options="options"
                  v-model="selectedOptions"
                  @change="handleChange"
                >
                </el-cascader>
                <el-input
                    style="margin-left:13px;"
                    v-model="form.adress"
                    placeholder="请输入详细地址"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="品牌名称" style="margin-right: 20px">
              <el-input v-model="form.definition" placeholder="填写品牌名"></el-input>
            </el-form-item>
        </div>
        <div class="form_btn">
            <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button type="success" @click="reset">重置</el-button>
            </el-form-item>
        </div>
      </el-form>
      <!-- 搜索内容 -->
      <div class="order_list">
        <div class="list_top">
          <div class="list_top_data" @click="selectlist(7)">
            <div class="data_one" :class="status==7?'select_data_one':''">所有订单<span v-show="status==7">({{totalnum}})</span></div>
            <div class="data_two" v-show="status==7"></div>
          </div>
          <div class="list_top_data" @click="selectlist(0)">
            <div class="data_one" :class="status==0?'select_data_one':''">待发布<span v-show="status==0">({{totalnum}})</span></div>
            <div class="data_two" v-show="status==0"></div>
          </div>
          <!-- <div class="list_top_data" @click="selectlist(1)">
            <div class="data_one" :class="status==1?'select_data_one':''">待抢单<span v-show="status==1">({{totalnum}})</span></div>
            <div class="data_two" v-show="status==1"></div>
          </div> -->
          <div class="list_top_data" @click="selectlist(2)">
            <div class="data_one" :class="status==2?'select_data_one':''">接单中<span v-show="status==2">({{totalnum}})</span></div>
            <div class="data_two" v-show="status==2"></div>
          </div>
          <div class="list_top_data" @click="selectlist(3)">
            <div class="data_one" :class="status==3?'select_data_one':''">待服务<span v-show="status==3">({{totalnum}})</span></div>
            <div class="data_two" v-show="status==3"></div>
          </div>
          <div class="list_top_data" @click="selectlist(4)">
            <div class="data_one" :class="status==4?'select_data_one':''">待验收<span v-show="status==4">({{totalnum}})</span></div>
            <div class="data_two" v-show="status==4"></div>
          </div>
          <div class="list_top_data" @click="selectlist(5)">
            <div class="data_one" :class="status==5?'select_data_one':''">已完成<span v-show="status==5">({{totalnum}})</span></div>
            <div class="data_two" v-show="status==5"></div>
          </div>
          <div class="list_top_data" @click="selectlist(6)">
            <div class="data_one" :class="status==6?'select_data_one':''">已评分<span v-show="status==6">({{totalnum}})</span></div>
            <div class="data_two" v-show="status==6"></div>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" height="562px"  :header-cell-style="{textAlign: 'center',backgroundColor:'#FAFAFA'}" :cell-style="{ textAlign: 'center' }">
            <el-table-column prop="orderNum" label="订单编号" width="180" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="faultTypeName" label="故障类型" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="address" label="上门地址" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="brandName" label="品牌名称" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="releaseTime" label="发布时间" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="finalPrice" label="费用" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="condition" label="状态" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="" label="管理">
                <template slot-scope="scope">
                  <el-button @click="see(scope.row)" type="text" size="small">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="fenye" v-if="pageshow">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage4" 
          :page-size="9"
          layout="total, prev, pager, next"
          :total="totalnum">
        </el-pagination>
      </div>
      <!-- 分页 -->
  </div>
</template>

<script>
import eventBus from '../../../api/goBackEntity.js';
import { MyWorkOrder } from '../../../api/request';
import { regionData, CodeToText } from "element-china-area-data";
export default {
name:"Myworkorder",
data(){
  return{
    options: regionData,//省市区组件内容
    selectedOptions: [],//省市区组件选择后的内容
    currentPage4:1,
    totalnum:0,//总条数
    status:7,
    form: {
      num: "",
      adress: "",
      definition: "",
    },
    provinces:'',
    city:'',
    area:'',
    tableData: [],
    id:'',
    pageshow:true
  }
},
created(){
  if(localStorage.getItem("id")){
    this.id =Number(localStorage.getItem("id")) 
    this.getallworkorder(this.status)
  }
},


beforeRouteLeave(to, from, next) {
  if (to.path == "/Orderdetails") {
    from.meta.keepAlive = true;
  } else {
    from.meta.keepAlive = false;
  }
  next();
},
activated(){
  var that = this
  eventBus.$once('id', function(data){
    // console.log("123456")
    that.getallworkorder(that.status)
  }.bind(this));
},
methods:{
  //获取所有工单
  getallworkorder(status){
    console.log(status)
    if(status == 7){
      var params = {
        enterpriseCustomersId:this.id,
        pageNum:1, 
        pageSize:9,
        type:null
      }
    }else{
      var params = {
        enterpriseCustomersId:this.id,
        pageNum:1, 
        pageSize:9,
        type:status
      }
    }
    MyWorkOrder(params).then(res=>{
      console.log(res)
      if(res.data.code == 200){
        this.totalnum = res.data.count
        var datalist = res.data.data
        for(var i=0;i<datalist.length;i++){
          datalist[i].constructionaddress = datalist[i].provinces+datalist[i].city+datalist[i].area+datalist[i].address
          if(datalist[i].type == 0){
            datalist[i].condition = "待发布"
          }
          if(datalist[i].type == 1){
            datalist[i].condition = "已发布"
          }
          if(datalist[i].type == 2){
            if (datalist[i].masterList!=null &&  datalist[i].masterList.length>0){
              datalist[i].condition = "接单中("+datalist[i].masterList.length+")"
            }else{
              datalist[i].condition = "待接单"
            }
          }
          if(datalist[i].type == 3){
            datalist[i].condition = "待服务"
          }
          if(datalist[i].type == 4){
            datalist[i].condition = "待验收"
          }
          if(datalist[i].type == 5){
            datalist[i].condition = "已完成"
          }
          if(datalist[i].type == 6){
            datalist[i].condition = "已评分"
          }
          if(datalist[i].type == 7){
            datalist[i].condition = "已取消"
          }
          if(datalist[i].type == 8){
            datalist[i].condition = "已拒绝"
          }
        }
        this.tableData = datalist
      }
    })
  },
  see(e){
    // console.log(e)
    this.$router.push({
      path: "/Orderdetails",
      name: "Orderdetails", // 要跳转的路径的 name,可在 router 文件夹下的 index.js 文件内找
      query: {msg:e}
    })
    // this.$router.push("/Orderdetails")
  },
  //重置
  reset(){
    this.form.num = ''
    this.form.adress = ''
    this.form.definition = ''
    this.provinces = ''
    this.city = ''
    this.area = ''
    this.selectedOptions = []
    this.tableData = []
    this.totalnum = 0
    this.currentPage4 = 1
    this.pageshow = false;//让分页隐藏
    this.$nextTick(() => {//重新渲染分页
        this.pageshow = true;
    });
    this.getallworkorder(7)
  },
  //查询
  onSubmit() {
    //if(this.form.num=='' && this.provinces=='' && this.city=='' && this.area=="" && this.form.adress=="" && this.form.definition==""){
    //  this.$message('请输入查询条件')
    //  return
    //}
    this.totalnum = 0
    this.currentPage4 = 1
    this.pageshow = false;//让分页隐藏
    this.$nextTick(() => {//重新渲染分页
        this.pageshow = true;
    });
    this.status = 7
    var params = {
      enterpriseCustomersId:this.id,
      pageNum:1, 
      pageSize:10,
      orderNum:this.form.num,
      provinces:this.provinces,
      city:this.city,
      area:this.area,
      deatilAddress:this.form.adress,
      brandName:this.form.definition
    }
    console.log(params)
    MyWorkOrder(params).then(res=>{
      console.log(res)
      //this.$message(res.data.msg)
      if(res.data.code == 200){
        this.totalnum = res.data.count
        var datalist = res.data.data
        for(var i=0;i<datalist.length;i++){
          datalist[i].constructionaddress = datalist[i].provinces+datalist[i].city+datalist[i].area+datalist[i].address
          if(datalist[i].type == 0){
            datalist[i].condition = "待发布"
          }
          if(datalist[i].type == 1){
            datalist[i].condition = "待抢单"
          }
          if(datalist[i].type == 2){
            datalist[i].condition = "待接单"
          }
          if(datalist[i].type == 3){
            datalist[i].condition = "待服务"
          }
          if(datalist[i].type == 4){
            datalist[i].condition = "待验收"
          }
          if(datalist[i].type == 5){
            datalist[i].condition = "已完成"
          }
          if(datalist[i].type == 6){
            datalist[i].condition = "已评分"
          }
          if(datalist[i].type == 7){
            datalist[i].condition = "已取消"
          }
          if(datalist[i].type == 8){
            datalist[i].condition = "已拒绝"
          }
        }
        this.tableData = datalist
      }
    })
  },
  //选择省市区
  handleChange(e) {
    for (let i = 0; i < this.selectedOptions.length; i++) {
      if(i==0){
        this.provinces = CodeToText[this.selectedOptions[i]]
      }
      if(i==1){
        this.city = CodeToText[this.selectedOptions[i]]
      }
      if(i==2){
        this.area = CodeToText[this.selectedOptions[i]]
      }
    }
    // console.log(this.provinces,this.city,this.area);
  },
  //切换工单状态
  selectlist(index){
    this.status = index
    this.form.num = ''
    this.form.adress = ''
    this.form.definition = ''
    this.provinces = ''
    this.city = ''
    this.area = ''
    this.selectedOptions = []
    this.tableData = []
    this.totalnum = 0
    this.currentPage4 = 1
    this.pageshow = false;//让分页隐藏
    this.$nextTick(() => {//重新渲染分页
        this.pageshow = true;
    });
    this.getallworkorder(index)
  },
  //切换分页
  handleCurrentChange(val) {
    if(this.form.num != '' || this.provinces!='' || this.city!='' || this.area!='' || this.form.adress!='' || this.form.definition!=''){
      var params = {
        enterpriseCustomersId:this.id,
        pageNum:val, 
        pageSize:9,
        orderNum:this.form.num,
        provinces:this.provinces,
        city:this.city,
        area:this.area,
        deatilAddress:this.form.adress,
        brandName:this.form.definition
      }
    }else{
      if(this.status == 7){
        var params = {
          enterpriseCustomersId:this.id,
          pageNum:val, 
          pageSize:9,
          type:null
        }
      }else{
        var params = {
          enterpriseCustomersId:this.id,
          pageNum:val, 
          pageSize:10,
          type:this.status
        }
      }
    }
    MyWorkOrder(params).then(res=>{
      console.log(res)
      if(res.data.code == 200){
        this.totalnum = res.data.count
        var datalist = res.data.data
        for(var i=0;i<datalist.length;i++){
          datalist[i].constructionaddress = datalist[i].provinces+datalist[i].city+datalist[i].area+datalist[i].address
          if(datalist[i].type == 0){
            datalist[i].condition = "待发布"
          }
          if(datalist[i].type == 1){
            datalist[i].condition = "待抢单"
          }
          if(datalist[i].type == 2){
            datalist[i].condition = "待接单"
          }
          if(datalist[i].type == 3){
            datalist[i].condition = "待服务"
          }
          if(datalist[i].type == 4){
            datalist[i].condition = "待验收"
          }
          if(datalist[i].type == 5){
            datalist[i].condition = "已完成"
          }
          if(datalist[i].type == 6){
            datalist[i].condition = "已评分"
          }
        }
        this.tableData = datalist
      }
    })
  },
}
}
</script>

<style scoped>
.workorder{
  padding-top: 27px;
}
.form_fle {
  display: flex;
}
.form_btn {
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
}
.shangmen{
  display: flex;
}
.order_list{
  /* background-color: red; */
}
.list_top{
  display: flex;
  /* justify-content: space-between; */
  padding:0px 14px;
  margin-bottom: 12px;
}
.list_top_data{
  cursor: pointer;
  margin-right: 40px;
}
.data_one{
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.data_two{
  width: 100%;
  height: 4px;
  background-color: #4A9BEC;
}
.select_data_one{
  color: #2C9EF4;
}
.fenye{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.chakan{
    color: #3290EF;
    cursor: pointer;
}
</style>