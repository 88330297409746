<template>
  <div id="app">
    <keep-alive><router-view v-if="$route.meta.keepAlive"></router-view></keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import {info,imgurl} from './api/request'
import {getLocal} from './libs/util'
import Vue from 'vue'
export default {
    created(){    
    },
    methods:{
    }
};
</script>
<style>
#app {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}
body, html, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, code, form, fieldset, legend, input, textarea, p, table, th, td, em, button {margin: 0;padding: 0;}
img {border: 0;}
a {text-decoration: none;cursor: pointer;}
i, em {font-style: normal;}
ul,li,ol{list-style: none;}
input{border: none;outline: none;}
</style>
