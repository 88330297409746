<template>
  <div class="login">
    <div class="login_content">
      <img class="login_left" src="../../assets/login/ziyuan.png" alt="" />
      <div class="login_right">
        <div class="login_title">速修美(用户端)</div>
        <div class="login_title_name">更方便快捷</div> 
        <div class="login_acc">
          <div class="acc_one">
            <img class="img_1" src="../../assets/login/account.png" alt="" />
          </div>
          <input type="text" placeholder="请输入手机号" v-model="account" />
          <div class="caa_two" v-show="show==1" @click="getverification">获取验证码</div>
          <div class="caa_two" v-show="show==0">{{Verificationcode}}秒</div>
        </div>
        <div class="login_psd">
          <div>
            <img class="img_2" src="../../assets/login/yanzhengma.png" alt="" />
          </div>
          <input type="text" placeholder="请输入验证码" v-model="code" />
        </div>
        <div class="login_psd">
          <div>
            <img class="img_3" src="../../assets/login/idcard.png" alt=""  />
          </div>
          <input type="text" placeholder="请输入身份证号" v-model="idcard" />
        </div>
        <div class="login_psd">
          <div>
            <img class="img_4" src="../../assets/login/psd.png" alt=""  />
          </div>
          <input type="password" placeholder="请设置密码" v-model="password" />
        </div>
        <div class="login_psd">
          <div>
            <img class="img_5" src="../../assets/login/psd.png" alt="" />
          </div>
          <input type="password" placeholder="请再次确认密码" v-model="confirmPassword" />
        </div>
        <div class="login_btn" @click="register">注册</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Register,SendCode} from '../../api/request';
import { setLocal,getLocal } from "../../libs/util";
export default {
  name: "Register",
  data(){
      return{
        Timer:'',//定时器
        show:1,//控制验证码倒计时显示
        account:'',//账号
        code:'',//验证码
        idcard:'',//身份证号
        password:'',//密码
        confirmPassword:"",//确认密码
        Verificationcode:15,//倒计时
      }
  },
  methods:{
    //获取验证码
    getverification(){
      if(this.account != ''){
        var account = RegExp(/^1[3456789]\d{9}$/).test(this.account);
        if(account == false){
          this.$message({
            message:'手机号格式错误',
            type:'error',
            duration:1000
          })
          return
        }
      }else{
        this.$message({
          message:'请输入手机号',
          type:'error',
          duration:1000
        })
        return
      }
      this.show = 0
      this.Timer = setInterval((res)=>{
        if(this.Verificationcode == 0){
          this.Verificationcode = 15
          this.show =1
          clearInterval(this.Timer)
          return
        }
        this.Verificationcode--
        console.log(this.Verificationcode)
      },1000)
      var params = {
        phone:this.account   
      }
      console.log(params)
      SendCode(params).then(res=>{
        console.log(res)
        if(res.data.code ==200){
          this.$message({
            message:res.data.msg, 
            type:'success',
            duration:1000
          })
        }else{
          this.$message({
            message:res.data.msg, 
            type:'error',
            duration:1000
          })
        }
        
      })
    },
    //注册按钮
    register(){
      if(this.code == ''){
        this.$message({
          message:'请输入验证码',
          type:'error',
          duration:1000
        })
        return
      }
      if(this.idcard == ''){
        this.$message({
          message:'请输入身份证号码',
          type:'error',
          duration:1000
        })
        return
      }else{
        let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        let _IDre15 =  /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
        if( _IDRe18.test( this.idcard ) || _IDre15.test( this.idcard )  ) {
          console.log(' 验证通过 ')
        } else {
          this.$message({
            message:'请输入正确的身份证号码',
            type:'error',
            duration:1000
          })
          return
        }
      }
      if(this.password== ''){
        this.$message({
          message:'请输入密码',
          type:'error',
          duration:1000
        })
        return
      }
      if(this.confirmPassword == ''){
        this.$message({
          message:'请再次输入密码',
          type:'error',
          duration:1000
        })
        return
      }
      if(this.password != this.confirmPassword){
        this.$message({
          message:'两次密码不一致',
          type:'error',
          duration:1000
        })
        return
      }
      var params = {
        phone:this.account,
        code:this.code,
        idCard:this.idcard,
        password:this.password,
        newPassword:this.password
      }
      Register(params).then(res=>{
        console.log(res)
        if(res.data.code == 200){
          this.$message({
            message:res.data.msg,
            type:'success',
            duration:1000
          })
          clearInterval(this.Timer)
          this.$router.go(-1);
        }else{
          this.$message({
            message:res.data.msg+":"+res.data.data,
            type:'error',
            duration:1000
          })
        }
      })
    }
  }
};
</script>

<style scoped>
.login {
    width: 100%;
    height: 100%;
    background: url(../../assets/login/bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_content {
    padding: 50px 94px 50px 57px;
    /* width: 1050px;
    height: 672px; */
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.login_left {
    margin-right: 90px;
    width: 450px;
    height: 306px;
}

.login_right {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.login_title {
    font-size: 44px;
    font-weight: bold;
}

.login_title_name {
    margin-top: 18px;
    font-size: 18px;
    color: #A9A9A9;
}

.login_acc {
    width: 360px;
    margin-top: 35px;
    display: flex;
    align-items: center;
    /* padding-bottom: 20px; */
    height: 60px;
    border-bottom: 1px solid #EEEEEE;
}
.acc_one{
    width: 50px;
}
.acc_one img {
    margin-right: 20px;
    width: 28px;
    height: 30px;
}

.login_acc input {
    width: 190px;
    font-size: 20px;
}
.caa_two{
    width: 109px;
    height: 34px;
    border: 1px solid #4A9BEC;
    border-radius: 17px;
    font-size: 16px;
    text-align: center;
    line-height: 34px;
    color: #4A9BEC;
    cursor: pointer;
}
.login_psd {
    width: 360px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    height: 60px;
    /* padding-bottom: 20px; */
    border-bottom: 1px solid #EEEEEE;
}

.login_psd div {
    width: 50px;
}

.img_1 {
    width: 28px;
    height: 30px;
}

.img_2 {
    width: 31px;
    height: 36px;
}
.img_3 {
    width: 34px;
    height: 25px;
}
.img_4 {
    width: 28px;
    height: 29px;
}
.img_5 {
    width: 28px;
    height: 29px;
}
.login_psd input {
    font-size: 20px;
}

.login_yonghu {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.login_yonghu img {
    margin-right: 20px;
    width: 20px;
    height: 20px;
}

.login_yonghu div {
    font-size: 18px;
    color: #919191;
}

.login_btn {
    margin-top: 40px;
    width: 320px;
    height: 66px;
    background-color: #1A72FE;
    font-size: 26px;
    text-align: center;
    line-height: 66px;
    border-radius: 33px;
    color: #FFFFFF;
    cursor: pointer;
}

.reg {
    margin: 34px auto 0px;
    font-size: 20px;
    color: #4A9BEC;
    cursor: pointer;
}
</style>