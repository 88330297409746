<template>
  <div>
    <div class="back" @click="back">返回上一级</div>
    <!-- 步骤条 -->
    <div class="buzhou">
      <el-steps :active="active" align-center>
        <el-step
          title="待发布"
          style="font-size: 14px"
          description=""
        ></el-step>
        <!-- <el-step title="待抢单" description=""></el-step> -->
        <el-step title="待接单" description=""></el-step>
        <el-step title="待服务" description=" "></el-step>
        <el-step title="待验收" description=" "></el-step>
        <el-step title="已完成" description=" "></el-step>
        <el-step title="已评分" description=" "></el-step>
      </el-steps>
    </div>
    <!-- 步骤条 -->
    <!-- 主题内容 -->
    <div class="details_btm">
      <div class="msg_list">
        <div class="list_left">订单编号：</div>
        <div class="list_right">{{msg.orderNum}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">故障类型：</div>
        <div class="list_right">{{msg.faultTypeName}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">故障描述：</div>
        <div class="list_right">{{msg.detail}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">录入时间：</div>
        <div class="list_right">{{msg.createTime}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">上门地址：</div>
        <div class="list_right">{{msg.provinces}}{{msg.city}}{{msg.area}}{{msg.address}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">订单备注：</div>
        <div class="list_right">{{msg.orderNote ? msg.orderNote : "未填写"}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">故障照片：</div>
        <div class="list_right_one">
          <img class="av" :src="imgBaseUrl+item" alt="暂无法显示" v-for="(item,index) in msg.pic" :key="index" />
        </div>
      </div>
      <div class="msg_list">
        <div class="list_left">品牌名称：</div>
        <div class="list_right">{{msg.brandName ? msg.brandName : "未填写"}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">发布途径：</div>
        <div class="list_right">{{msg.genre==0?'一口价':'询价'}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">联系客户：</div>
        <div class="list_right">{{msg.contact}}/{{msg.contactPhoneNumber}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">上门时间：</div>
        <div class="list_right">{{msg.doorOfTime}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left" v-if="msg.genre==0">订单金额：</div>
        <div class="list_left" v-if="msg.genre!=0">参考价格：</div>
        <div class="list_right price">{{msg.finalPrice}}元</div>
      </div>
	  
	  <div class="msg_list">
	    <div class="list_left">保险金额：</div>
	    <div class="list_right price">{{msg.insurancePrice}}元</div>
	  </div>
	  
	  <div class="msg_list">
	    <div class="list_left">服务费金额：</div>
	    <div class="list_right price">{{msg.serviceFee}}元</div>
	  </div>
	  
	  
	  
    </div>
    <!-- 主题内容 -->
    <!-- 待接单师傅内容 -->
    <div class="teacher" v-if="active == 2">
      <el-table :data="tableData" stripe :header-cell-style="{ textAlign: 'center', backgroundColor: '#FAFAFA' }" :cell-style="{ textAlign: 'center' }">
        <el-table-column label="师傅头像" width="120">
          <template slot-scope="scope">
            <el-image class="head_pic" :src="scope.row.headPic" fit="cover" ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="师傅名字" width="120"></el-table-column>
        <el-table-column prop="phone" label="联系电话" width="180"> </el-table-column>
        <el-table-column prop="favorableRating" label="好评率" width="80"> </el-table-column>
        <el-table-column prop="takeOrderSize" label="接单量" width="80"> </el-table-column>
        <el-table-column prop="skill" label="技能证" width="80"> </el-table-column>
        <el-table-column prop="province" label="服务地区" width="100"> </el-table-column>
		    <el-table-column prop="price" label="报价" width="80"> </el-table-column>
        <el-table-column prop="" label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="selectteacher(scope.row)" type="text" size="small">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 待接单师傅内容 -->
    <!-- 接单之后内容 -->
    <div class="serve" v-if="active == 3 || active == 4 || active == 5 || active == 6">
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ textAlign: 'center', backgroundColor: '#FAFAFA' }" :cell-style="{ textAlign: 'center' }">
            <el-table-column label="师傅头像" width="180">
              <template slot-scope="scope">
                <el-image class="head_pic" :src="scope.row.headPic" fit="cover" ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="师傅名字" width="180">
            </el-table-column>
            <el-table-column prop="phone" label="联系电话"> </el-table-column>
            <el-table-column prop="favorableRating" label="好评率"> </el-table-column>
            <el-table-column prop="takeOrderSize" label="接单量"> </el-table-column>
            <el-table-column prop="skill" label="技能证"> </el-table-column>
            <el-table-column prop="region" label="服务地区"> </el-table-column>
        </el-table>
        <div class="photo" v-if="active == 4 || active == 6 || active == 5 || msg.type==8">
            <div class="photo_title">施工照片</div>
            <div class="photo_list">
                <div class="photo_list_datas">
                    <div class="photo_left">施工前照片:</div>
                    <div class="photo_eight" v-if="active == 4 || active == 6 || active == 5 || msg.type==8">
                        <img v-for="(item,index) in msg.takePhotosBeforeInstallation" :key="index" :src="imgBaseUrl+item" alt="暂无法显示">
                    </div>
                </div>
                <!-- <div class="photo_list_datas">
                    <div class="photo_left">施工中照片:</div>
                    <div class="photo_eight" v-if="active == 5 || active == 7"></div>
                </div> -->
                <div class="photo_list_datas">
                    <div class="photo_left">施工完照片:</div>
                    <div class="photo_eight" v-if="active == 4 || active == 6 || active == 5 || msg.type==8">
                      <img v-for="(item,index) in msg.takePhotosAfterInstallation" :key="index" :src="imgBaseUrl+item" alt="暂无法显示">
                    </div>
                </div>
				
				
                <div class="photo_list_datas">
                    <div class="photo_left">验收单照片:</div>
                    <div class="photo_eight" v-if="active == 4 || active == 6 || active == 5 || msg.type==8">
                    <img :src="imgBaseUrl+ msg.photoOfTheReceipt" alt="暂无法显示">
                    </div>
                </div>
				        <div style="margin:40px" v-if="msg.type==8">
                  拒绝验收原因: <span style="color:red">{{msg.refuseToReason}} / {{msg.refuseToReasonTime}}</span>
                </div>
				
                <!-- <div class="photo_list_datas">
                    <div class="photo_left">验收单照片:</div>
                    <div class="photo_eight" v-if="active == 4 || active == 6 || active==5">
                        <div class="imggg" v-if="active == 4">
                            <img width="100%" v-show="ImageUrl.length!=0" v-for="(item,index) in ImageUrl" :key="index" :src="imgBaseUrl+item" alt="">
                            <el-upload
                                class="avatar-uploader"
                                :action="imgBaseUrl+'/app/uploadFileToData'"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                                :multiple="false"
                                list-type="picture">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100px" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </div>
                        <div v-if="active == 6 || active==5">
                            <img v-if="msg.photoOfTheReceipt!=null" :src="imgBaseUrl+msg.photoOfTheReceipt" alt="">
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
     <!-- 接单之后内容 -->

    <div class="ping" v-if="active == 5">
      <div>来个师傅评个分吧！</div>
      <div>服务态度：<el-rate v-model="score"></el-rate></div>
      <div>响应速度：<el-rate v-model="respondScore"></el-rate></div>
      <div>服务质量：<el-rate v-model="qualityScore"></el-rate></div>
      <textarea name="" id="" cols="30" rows="5" placeholder="请输入您的评价" v-model="inp"></textarea>
    </div>

    <!-- 底部按钮系列 -->
    <div class="details_btm_btm">
      <div v-if="active == 5" @click="evaluationscore">立即评分</div>
      <!-- <div v-if="active == 2 || active == 1">修改价格</div> -->
      <div v-if="active == 6 || active == 2" @click="againorder">再来一单</div>
      <div v-if="active == 1" @click="releaseBtns">立即发布</div>
      <div v-if="active == 1" @click="editBtn">编辑订单</div>
      <div v-if="active == 2 || active == 1" @click="cancleorder">取消订单</div>
      <div v-if="active == 4" @click="rejectacceptance">拒绝验收</div>
      <div v-if="active == 4" @click="confirmacceptance">确认验收</div>
    </div>
    <!-- 底部按钮系列 -->

    <el-dialog
      title="拒绝原因"
      :visible.sync="refult"
      width="30%">
      <el-input type="textarea" style="height" v-model="reason" placeholder="请输入拒绝原因"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="calloff">取 消</el-button>
        <el-button @click="ascertain">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import eventBus from '../../../api/goBackEntity.js';
import { CancelWorkOrder,Score,ChooseMaster,ToRefuseAcceptance,ToDetermineTheAcceptance,immediatelyRelease,getRepairOrderById} from '../../../api/request';
import { imghost} from '../../../api/common';
export default {
  name: "Orderdetails",
  data() {
    return {
      score:0,
      respondScore:0,
      qualityScore:0,
      active: 1, 
      dialogImageUrl: '',
      ImageUrl:[],
      imgBaseUrl:imghost,
      dialogVisible: false,
      refult:false,
      reason:'',
      fileList:[],
      tableData: [],
      inp:'',//评价内容
	  ids:"",
    };
  },
  created(){
    if(this.$route.query.msg){
      this.msg = this.$route.query.msg
      if(this.msg.type==0){
        this.active =  Number(this.msg.type)+1
      }else if(this.msg.type==7||this.msg.type==8) {
        this.active = 3
      }else{
        this.active =  Number(this.msg.type)
      }
      
      this.msg.pic = this.msg.pic==null?[]:this.msg.pic.split(",")
      var list = this.msg.masterList
      if(list!=null){
        for(var i=0;i<list.length;i++){
          if(list[i].headPic != null && list[i].headPic!=''){
            list[i].headPic = this.imgBaseUrl+list[i].headPic
          }
          if(list[i].isElectricianAudit == 0){
            list[i].skill = ''
          }else{
            list[i].skill = '电工证'
          }
          list[i].region = list[i].province+list[i].city+list[i].area
        }
        this.tableData = list;
		console.log(this.tableData);
		console.log("这个")
      }
      var listimg = this.msg.takePhotosBeforeInstallation
      if(listimg!=null&&listimg!=''){
        // console.log("123456")
        listimg=listimg.split(',')
      }
      this.msg.takePhotosBeforeInstallation = listimg
      var listafterimg = this.msg.takePhotosAfterInstallation
      if(listafterimg!=null&&listafterimg!=''){
        listafterimg=listafterimg.split(',')
      }
      this.msg.takePhotosAfterInstallation = listafterimg
      console.log(this.$route.query.msg);
	  
	  this.ids = Number(localStorage.getItem("id"))
	  
    }
  },
  
  
  methods: {

    //确认验收
    confirmacceptance(){
      // if(this.ImageUrl.length == 0){
      //   this.$message("请上传验收单照片")
      //   return
      // }
      var img = this.ImageUrl.toString()
      var params = {
        id:this.msg.id,
        // photoOfTheReceipt:img
		photoOfTheReceipt:this.msg.photoOfTheReceipt
      }
      ToDetermineTheAcceptance(params).then(res=>{
        this.$message(res.data.msg)
        if(res.data.code == 200){
          eventBus.$emit('id',1);
          this.$router.go(-1);
        }
      })
    },
    //关闭拒绝验收弹窗
    calloff(){
      this.refult=false
      this.reason = ""
    },
    //确定拒绝验收弹窗
    ascertain(){
      if(this.reason==''){
        this.$message("请输入拒绝原因")
        return
      }
      var img = this.ImageUrl.toString()
      var params = {
        id:this.msg.id,
        refuseToReason:this.reason,
        // photoOfTheReceipt:img
		photoOfTheReceipt:this.msg.photoOfTheReceipt
      }
      ToRefuseAcceptance(params).then(res=>{
        console.log(res)
        this.$message(res.data.msg)
        if(res.data.code == 200){
          eventBus.$emit('id',1);
          this.$router.go(-1);
        }
      })
      // console.log(params)
    },
    //上传验收单照片前判断是否为图片
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.type === 'image/png';
      if (isJPG==false && isLt2M==false) {
        this.$message.error('上传文件需为图片');
      }
      return isJPG || isLt2M;
    },
    //上传图片
    handleAvatarSuccess(res, file){
      this.ImageUrl = this.ImageUrl.concat(res)
    },
    //显示拒绝验收弹窗
    rejectacceptance(){
      // if(this.ImageUrl.length == 0){
      //   this.$message("请上传验收单照片")
      //   return
      // }
      this.refult=true
    },
    //提交评分
    evaluationscore(){
      if(this.score == 0){
        this.$message("请为师傅评个分吧")
        return
      }
      if(this.inp == ''){
        this.$message('请为师傅写个评价吧')
        return
      }
      var params = {
        id:this.msg.id,
        score:this.score,
        respondScore:this.qualityScore,
        qualityScore:this.qualityScore,
        evaluation:this.inp
      }
      console.log(params)
      Score(params).then(res=>{
        console.log(res)
        this.$message(res.data.msg)
        if(res.data.code == 200){
          eventBus.$emit('id',1);
          this.$router.go(-1);
        }
      })
    },
    //返回上一路由页面
    back() {
      this.$router.go(-1);
    },
	
	releaseBtns(){ //立即发布
		console.log("111")
		
		var params = {
		  malfunctionId:this.msg.id
		}
		immediatelyRelease(params).then(res=>{
		  // this.$message(res.data.msg)
		  if(res.data.code == 200){
			  this.$message("发布成功")
		    eventBus.$emit('id',1);
		    this.$router.go(-1);
		  }else{
			  this.$message(res.data.data) 
		  }
		})
		
	},
	
	editBtn(){ //编辑
	// this.msg
		localStorage.setItem("editIPT",JSON.stringify(this.msg));
		
		this.$router.push('/Release');
		
	},
	
    //取消订单
    cancleorder(){
      var params = {
        malfunctionId:this.msg.id
      }
      CancelWorkOrder(params).then(res=>{
        this.$message(res.data.msg)
        if(res.data.code == 200){
          eventBus.$emit('id',1);
          this.$router.go(-1);
        }
      })
    },
    //选择接单师傅
    selectteacher(e){
    this.$confirm('确认选择该师傅？')
      .then(_ => {
        var params = {
          id:this.msg.id,
          masterId:e.id,
          enterpriseCustomerId:this.ids
        }
        console.log(params)
        ChooseMaster(params).then(res=>{
          console.log(res)
          if(res.data.code == 200){
            this.getRepairOrderById()
            eventBus.$emit('id',1);
            this.$router.go(-1);
          }else{
            this.$alert(res.data.msg+":"+res.data.data)
          }
        })
      })
      .catch(_ => {});
    },
	
	
	getRepairOrderById(){
		var params = {
			id:this.ids 
		}
		getRepairOrderById(params).then(res=>{
		  console.log(res)
		  if(res.data.code == 200){
		    localStorage.setItem("banlance",JSON.stringify(res.data.data.banlance));
		  }else{
		    this.$message(res.data.data)
		  }
		})
	},
	
	
	
    //再来一单
    againorder(){
      this.$router.push('/Release')
    },
    beforeupload(e){
        console.log(e)
    },
    handleRemove(file, fileList) {
    console.log(file, fileList);
    },
    handlePreview(file) {
    console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    }
  },
  
 
  
  
  
};
</script>

<style scoped>
.el-rate__item /deep/ .el-rate__icon{
  font-size: 30px !important;
}
.back {
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  padding-right: 30px;
  text-align: right;
}
.el-step__title {
  font-size: 14px !important;
}
.buzhou {
  margin-top: 30px;
}
.details_btm {
  margin-top: 30px;
}
.msg_list {
  margin-top: 14px;
  display: flex;
}
.list_left {
  margin-left: 50px;
  margin-right: 20px;
  width: 150px;
  text-align: right;
  font-size: 14px;
}
.list_right_one {
  width: 80%;
  flex-wrap: wrap;
}
.av {
  margin-right: 20px;
  width: 120px;
  height: 120px;
  border-radius: 5px;
}
.list_right {
  font-size: 14px;
}
.price {
  color: #ff2222;
}
.details_btm_btm {
  margin: 37px 0px;
  display: flex;
  justify-content: center;
}
.details_btm_btm div {
  cursor: pointer;
  margin: 0px 20px;
  width: 138px;
  height: 53px;
  text-align: center;
  line-height: 53px;
  border-radius: 3px;
  background-color: #4a9bec;
  font-size: 16px;
  color: #ffffff;
}
.teacher,.serve,.photo{
    margin-top: 23px;
}
.chakan{
    color: #3290EF;
    cursor: pointer;
}
.photo_title{
    padding-left: 60px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    background-color: #F5F5F5;
}
.photo_list_datas{
    display: flex;
    line-height: 40px;
}
.photo_left{
    width: 125px;
    font-size: 14px;
    text-align: right;
    margin-right: 40px;
}
.photo_eight{
    width: 80%;
}
.photo_eight img{
    margin-top: 14px;
    margin-right: 20px;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    /* background-color: red; */
}
.el-upload--picture-card{
    width: 100px;
    height: 100px;
}
.ping{
  margin-top: 60px;
  text-align: center;
}
.ping div{
  margin-bottom: 10px;
  font-size: 14px;
}
.imggg{
  display: flex;
  align-items: flex-end;
}
.imggg img{
  margin-right: 14px;
  width: 126px;
  height: 126px;
  border-radius: 10px;
}
/* .avatar-uploader{
    width: 126px;
    height: 126px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload {
    border: 1px solid #cccccc;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 126px;
    height: 126px;
    line-height: 126px;
    text-align: center;
} */
.head_pic{
    width: 50px;
    height: 50px;
}
</style>