<!-- UserAgreement.vue  用户协议 -->
<template>
	<div class="UserAgreement">
		<div class="UserAgreement_1">
			<div class="UserAgreement_1_1">《协议条款》</div>
			<div class="">
				{{detail}}
			</div>
		</div>
	</div>
</template>

<script>
	import {xieyi} from '../../api/request.js';
	export default {
		name:'UserAgreement',
		data(){
			return{
			  detail:"",
			}
		},
		created(){
		 this.getAgreement()
		  
		},
		methods:{
			getAgreement(){//获取pc协议
				var params = {}
				xieyi(params).then(res=>{
				    console.log(res)
				    if(res.data.code == 200){
				        this.detail = res.data.data.detail
				    }
				})
			},
		}
	}
</script>

<style scoped="">
	.UserAgreement_1{
		width: 1200px;
		min-height: 400px;
		/* background: #1A72FE; */
		margin: 0 auto;
	}
	.UserAgreement_1_1{
		width: 100%;
		height: 100px;
		/* background: #26AD72; */
		line-height: 100px;
		font-size: 28px;
		text-align: center;
	}
</style>
