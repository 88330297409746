<template>
  <div class="recharge">
    <!-- 导航栏部分 -->
   <div class="recharge_top">
      <div class="recharge_top_left">
        <div class="top_left_list" @click="selectbar(1)">
          <div :class="bar == 1 ? 'select_list' : ''">银行转账支付</div>
          <div v-show="bar == 1"></div>
        </div>
        <div class="top_left_list" @click="selectbar(2)">
          <div :class="bar == 2 ? 'select_list' : ''">扫码快捷支付</div>
          <div v-show="bar == 2"></div>
        </div>
        <div class="top_left_list" @click="selectbar(3)">
            <div :class="bar == 3 ? 'select_list' : ''">交易记录</div>
            <div v-show="bar == 3"></div>
        </div>
      </div>
    </div>
	
	<!-- <div class="recharge_top">
	   <div class="recharge_top_left">
	    
	     <div class="top_left_list" @click="selectbar(2)">
	       <div :class="bar == 2 ? 'select_list' : ''">其他付款方式</div>
	       <div v-show="bar == 2"></div>
	     </div>
	   </div>
	   <div class="recharge_top_right">交易记录</div>
	 </div> -->
	
	
    <!-- 导航栏部分 -->

    <!-- 银行卡付款 -->
    <div class="bankcard" v-show="bar == 1">
        <div class="bank_content">
            <div class="bank_list"> <div>公司名称：</div> <div>{{msg.companyName}}</div></div>
            <div class="bank_list"><div>汇款账号：</div> <div>{{msg.remittancesAccount}}</div></div>
            <div class="bank_list"><div>开户行：</div> <div>{{msg.openingBank}}</div></div>
            <div class="bank_tishi">温馨提示：银行卡转账后，可将转账凭证截图发给客服</div>
        </div>
    </div>
    <!-- 银行卡付款 -->

    <!-- 其他付款方式 -->
    <div class="other" v-show="bar == 2">
        <div class="other_price">
            <div class="other_price_one" :class="paystatus==1?'select_price':''" @click="selectprice(1)">100元</div>
            <div class="other_price_one" :class="paystatus==2?'select_price':''" @click="selectprice(2)">200元</div>
            <div class="other_price_one" :class="paystatus==3?'select_price':''" @click="selectprice(3)">300元</div>
            <div class="other_price_one" :class="paystatus==4?'select_price':''" @click="selectprice(4)">500元</div>
        </div>
        <div class="other_custom">
            <div class="other_custom_one">自定义金额</div>
            <input type="number" placeholder="最低1元" v-model="price" @focus="bindfocus">
            <div class="other_custom_two" @click="determine">确定</div>
        </div>
        <div class="other_custom">扫码支付完成后，请点击右上角余额后面的刷新按钮!</div>
		
		<!-- <view class="pay_price_1">
			
		</view> -->
		
		
		<block v-if="paystatus != 0 || eeeee  ">
		
        <div class="pay_price"><div class="pay_price_one">应付金额：</div><div class="pay_price_two">{{payprice}}</div><div class="pay_price_one">元</div></div>
        <div class="erweima">
            <div class="erweima_list">
                <div class="erweima_list_one one_lan"><div id="qrcodeWX"  ref="qrcodeWX"></div></div>
                <div class="erweima_list_two">
                    <img src="../../../assets/rightpay/wei.png" alt="">
                    <div>微信支付</div>
                </div>
            </div>
            <div class="erweima_list">
                <div class="erweima_list_one one_lan"><div id="qrcode" ref="qrcode"></div></div>
                <div class="erweima_list_two">
                    <img src="../../../assets/rightpay/bao.png" alt="">
                    <div>支付宝支付</div>
                </div>
            </div>
        </div>
		
        <!-- <div class="collection">
            <div class="collection_list">
                <div>收款方：</div>
                <div>aaa</div>
            </div>
            <div class="collection_list">
                <div>订单号：</div>
                <div>aaa</div>
            </div>
            <div class="collection_list">
                <div>交易金额：</div>
                <div>aaa</div>
            </div>
        </div> -->
        <div class="other_tishi">温馨提示：付款后 可在交易记录查询</div>
		
		</block>
    </div>
    <!-- 充值列表 -->
    <div class="record" v-show="bar == 3">
        <el-table class="tablebox" :data="chargeRecordData" stripe style="width: 100%" height="676"  :header-cell-style="{textAlign: 'center',backgroundColor:'#FAFAFA'}" :cell-style="{ textAlign: 'center' }">
            <el-table-column prop="createTime" label="充值日期" width="180"></el-table-column>
            <el-table-column prop="money" label="充值金额"></el-table-column>
            <el-table-column prop="withdrawalWay" label="充值途径" >
                <template slot-scope="scope">
                    {{scope.row.withdrawalWay==1?"支付宝":"微信"}}
                </template>
            </el-table-column>
        </el-table>
        <div class="fenye">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="currentPage" 
                :page-size="11"
                layout="total, prev, pager, next"
                :total="totalnum">
            </el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { PrepaidPhonePrices,PrepaidPhonePricesw,PayMessage,TransactionList} from '../../../api/request';
import axios from 'axios'
 export default {
  name: "Rightrecharge",
  data() {
    return {
      id:'',
      bar: 2,
      paystatus:0,//其他付款方式金额选中的样式
      price:'',//其他付款方式自定义金额
      payprice:0,
      paysrc:'',
      aa:0,
      msg:'',
	  
	  bb:0,
	  paysrcWX:"",
	  eeeee:false,
      chargeRecordData:[],
	  currentPage:1,
      totalnum:0
    };
  },  
  created(){
    if(localStorage.getItem("id")){
        this.id =Number(localStorage.getItem("id")) 
    }
    this.getbankmsg()
  },
  methods: {
    //获取银行卡支付信息
    getbankmsg(){
        var params = {}
        PayMessage(params).then(res=>{
            if(res.data.code == 200){
                this.msg = res.data.data[0]
                // console.log(res.data.data[0])
            }
        })
    },
    //选择银行卡支付和其他付款方式
    selectbar(index) {
      this.bar = index;
      if (index==3){
          this.loadRecord();
      }
    },
    //获取充值记录列表
    loadRecord(){
        var params={customerId:this.id}
        TransactionList(params).then(res=>{
            this.totalnum = res.data.count;
            this.chargeRecordData = res.data.data;
        });
    },
    //选择其他付款方式中的金额
    selectprice(index){
        this.paystatus = index
        this.price=''
        if(index == 1){
            this.payprice = 100
        }
        if(index == 2){
            this.payprice = 200
        }
        if(index == 3){
            this.payprice = 300
        }
        if(index == 4){
            this.payprice = 500
        }
        this.alipay();
		this.wx()
    },
    //监听input获取焦点
    bindfocus(e){
        this.paystatus=0
        this.payprice = 0
    },
    //其他付款方式点击确定
    determine(){
        if(this.price == ''){
            this.$message('请输入自定义金额');
            return
        }
		
		this.eeeee = true;
		
		
        this.payprice = this.price
        this.alipay();
		this.wx()
    },
    //支付
    alipay(){
        if(this.aa == 1){
            this.$refs.qrcode.innerHTML = ''
        }
        var params = {
            enterpriseCustomersId:this.id,
            price:this.payprice
        }
        PrepaidPhonePrices(params).then(res=>{
			console.log(res)
			console.log("支付宝")
            if(res.data.code == 200){
                this.paysrc = res.data.data
                this.$nextTick(() => {
                    this.crateQrcode()
                })
            }
        });

		
    },
	
	// 微信
	
	wx(){
		console.log("微信");
		
		
		if(this.bb == 1){
		    this.$refs.qrcodeWX.innerHTML = ''
		}
		var params = {
		    enterpriseCustomersId:this.id,
		    price:this.payprice
		}
		console.log(params)
		PrepaidPhonePricesw(params).then(res=>{
			console.log(res)
			console.log("微信")
		    if(res.data.code == 200){
				console.log(res.data)
		        this.paysrcWX = res.data.data.object.code_url
		        this.$nextTick(() => {
		            this.crateQrcodew()
		        })
		    }
		});
		
		
		
	},
	
	
	
	
    // 生成二维码
    crateQrcode () {
      this.qr = new QRCode('qrcode', {
        width: 126,
        height: 126, // 高度
        text: this.paysrc // 二维码内容
      })
      this.aa = 1;

    },
	
	// 生成二维码
	crateQrcodew () {

	  this.qr = new QRCode('qrcodeWX', {
	    width: 126,
	    height: 126, // 高度
	    text: this.paysrcWX // 二维码内容
	  })
	  this.bb = 1
	  
	  
	},
	
    //获取分页数据
    handleCurrentChange(val){
      var params = {
        pageNum:val,
        pageSize:11,    
        enterpriseCustomersId:this.id
      }
    //   RecordsOfMakeOutAnInvoice(params).then(res=>{
    //     console.log(res)
    //     if(res.data.code == 200){
    //       var datalist = res.data.data
    //       for(var i=0;i<datalist.length;i++){
    //         if(datalist[i].invoiceState == 0){
    //           datalist[i].states = '待邮寄'
    //         }else{
    //           datalist[i].states = '已邮寄'
    //         }
    //       }
    //       this.tableData = datalist
    //     }
    //   })
    },
	
  },
};
</script>

<style scoped>
.recharge {
  overflow-y: scroll;
}
.recharge_top {
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
}
.recharge_top_right {
  font-size: 16px;
  color: #888888;
}
.recharge_top_left {
  display: flex;
  justify-content: flex-start;
}
.top_left_list {
  margin-right: 60px;
  cursor: pointer;
}
.top_left_list div:first-child {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
}
.top_left_list div:last-child {
  height: 4px;
  width: 100%;
  background-color: #4a9bec;
}
.select_list {
  color: #4a9bec;
}
.bankcard{
    padding: 49px 0px 0px 68px;
}
.bank_content{
    width: 750px;
    background-color: #F4F9FF;
    box-sizing: border-box;
    padding: 32px 51px;
}
.bank_list{
    display: flex;
}
.bank_list div:first-child{
    text-align: right;
    font-size: 16px;
    width: 130px;
    line-height: 36px;
}
.bank_list div:last-child{
    margin-left: 10px;
    font-size: 16px;
    line-height: 36px;
}
.bank_tishi{
    margin-top: 30px;
    font-size: 14px;
    color: #999999;
}
.other{
    padding: 45px 0px 0px 44px;
}
.other_price{
    display: flex;
}
.other_price_one{
    margin-right: 24px;
    width: 200px;
    height: 98px;
    border-radius: 8px;
    border: 1px solid #A7A7A7;
    font-size: 26px;
    text-align: center;
    line-height: 98px;
    cursor: pointer;
}
.select_price{
    border: 1px solid #4A9BEC;
    background-color: #EFF7FF;
}
.other_custom{
    margin-top: 40px;
    display: flex;
    /*align-items: center;*/
}
.other_custom input{
    margin-left: 18px;
    margin-right: 32px;
    width: 188px;
    height: 36px;
    text-align: center;
    border: 1px solid #A7A7A7;
    border-radius: 3px;
}
.other_custom_one{
    font-size: 16px;
}
.other_custom_two{
    width: 65px;
    height: 38px;
    background-color:  #4A9BEC;
    color: #FFFFFF;
    text-align: center;
    line-height: 38px;
    font-size: 16px;
    border-radius: 3px;
    cursor: pointer;
}


/* .pay_price_1{
	width: 100%;
	height: 400px;
	background: #02AAEF;
} */


.pay_price{
    margin-top: 75px;
    display: flex;
    /*justify-content: center;*/
}
.pay_price_one{
    font-size: 16px;
}
.pay_price_two{
    font-size: 16px;
    color: #FF7800;
}
.erweima{
    margin-top: 30px; 
    display: flex;
    /* justify-content: space-around; */
	/*justify-content: center;*/
	
}
.erweima_list{
    display: flex;
    flex-direction: column;
    align-items: center;
	margin-right: 30px;
    padding:50px;
}
.erweima_list_one{
    border: 1px solid #4AAF1A;
    padding: 10px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.erweima_list_one div{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
	
}
 .erweima_list_one img{
	width: 126px !important;
	height: 126px !important; 
}


.erweima_list_two{
    margin-top: 16px;
    display: flex;
    align-items: center;
}
.erweima_list_two img{
    width: 32px;
    height: 31px;
}
.erweima_list_two div{
    font-size: 14px;
    margin-left: 10px;
}
.one_lan{
    border: 1px solid #02AAEF;
}
.collection{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.collection_list{
    display: flex;
}
.collection_list div:first-child{
    width: 120px;
    font-size: 14px;
    text-align: right;
}
.collection_list div:last-child{
    font-size: 14px;
}
.other_tishi{
    margin-top: 60px;
    /*text-align: center;*/
    font-size: 14px;
    color: #999999;
}
.record{
    width: 100%;
}
.fenye{
    margin-top: 14px;
    display: flex;
    justify-content: center;
}

</style>