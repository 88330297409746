<template>
  <div class="release">
      <div class="release_top">
          <el-form ref="form" :model="form" label-width="80px">
            <!-- 上半部分 -->
            <el-form-item style="margin-left:53px;" label="*工单类型">  
              <el-select v-model="form.faultone" placeholder="点击选择" @change="selectfaulttype" style="width:630px">
                <el-option v-for="item in faultdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
			
            <el-form-item style="margin-left:53px;" label="*工单描述">
              <el-input style="margin-bottom:14px;width:630px;" type="textarea" v-model="form.describe"></el-input>
              <div class="imggg">
                <img width="100%" v-show="ImageUrl.length!=0" v-for="(item,index) in ImageUrl" :key="index" :src="imgBaseUrl+'/'+item" alt="">
                <el-upload
                  class="avatar-uploader"
                  :action="imgBaseUrl+'/app/uploadFileToData'"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :multiple="false"
                  list-type="picture">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <!-- <el-dialog :visible.sync="dialogVisible"> -->
              
              <!-- </el-dialog> -->
              <!-- <el-button size="small" type="primary" @click="submitUpload">点击上传图片</el-button> -->
            </el-form-item>
			
            
            
            <el-form-item style="margin-left:53px;" label="*上门时间">
              <el-date-picker
                v-model="form.todoor"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change='selecttodoortime'>
              </el-date-picker>
            </el-form-item>
            <el-form-item style="margin-left:53px;" label="*上门地址">
              <div class="shangmen">
                <el-cascader
                  size="large"
                  :options="options"
                  v-model="selectedOptions"
                  @change="handleChange"
                >
                </el-cascader>
                <el-input
                    style="margin-left:13px;width:400px"
                    v-model="form.detailAddress"
                    placeholder="请输入详细地址"
                ></el-input>
              </div>
			  
			  
			  
            </el-form-item>
			
			<el-form-item style="margin-left:53px;" label="*联系人">
			  <el-input style="width:220px;" v-model="form.name" placeholder="姓名"></el-input>
			  <el-input style="margin-left: 10px;width:400px;" v-model="form.phone" placeholder="联系电话"></el-input>
			  <!-- <el-button style="margin-left: 20px;width:120px;" type="primary">添加联系人+</el-button> -->
			</el-form-item>
			
            <!-- 上半部分 -->
            <div class="release_center"></div>
            <!-- 下半部分 -->
			
			      <div class="sfff" v-if="isServiceAccount">斐鴿傳書（灯控平台）用户必须填写以下信息，其他用户可选填物流及备注:</div>
			      <div class="store" v-if="isServiceAccount">
              <el-form-item style="margin-left:53px;" label="*服务品牌">
                <el-select v-model="form.brandid" placeholder="点击选择" @change="selectbrand">
                  <el-option v-for="item in brandlist" :key="item.id" :label="item.storeName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              
              <el-form-item style="margin-left:53px;" label="*服务门店">
                <el-select v-model="form.store" placeholder="点击选择" @change="selectstore" style="width:280px">
                  <el-option v-for="item in storelist" :key="item.id" :label="item.storeName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item style="margin-left:53px;" label="物流信息" v-if="isServiceAccount">
              <el-input style="width:220px;" v-model="form.logistics" placeholder="请输入物流公司"></el-input>
              <el-input style="margin-left: 20px;width:170px;" v-model="form.expressnum" placeholder="填写单号"></el-input>
              <el-input style="margin-left: 20px;width:200px;" v-model="form.expressphone" placeholder="发货人手机号"></el-input>
            </el-form-item>
            <el-form-item style="margin-left:53px;" label="订单备注">
              <el-input style="margin-bottom:14px;width:630px;" type="textarea" placeholder="请输入内容" v-model="form.remarks"></el-input>
            </el-form-item>
            <!-- <el-form-item style="margin-left:53px;" label="其他">
              <el-radio-group v-model="radio">
                <el-radio :label="3">施工证</el-radio>  
                <el-radio :label="6">高空证</el-radio>
                <el-radio :label="9">其他证</el-radio>	
              </el-radio-group>
            </el-form-item> -->
            <!-- 下半部分 -->
            <el-form-item style="margin-left:53px;">
              <el-button type="primary" @click="submitOrder">提交订单</el-button>
            </el-form-item>
            <el-form-item>
              <el-dialog
                title="发布订单"
                :visible.sync="show"
                width="40%"
                :before-close="handleClose"
                center
                :show-close="false">
                <el-radio-group v-model="order" style="width:100%" @change="selelctreleasetype">
                  <el-form-item style="width:100%">
                    <div style="display:flex;width:100%;align-items: center;">
                      <el-radio style="width:60px;" :label="0">一口价</el-radio>
                      <div class="hui">
                        <div class="hui_title">以固定价格发布，师傅对该价格进行抢单，需提前托管资金到平台</div>
                        <div class="hui_inp">
                          <el-input @keyup.native="keyup()" style="width:136px;height: 30px;" :disabled="order==1" v-model="form.price" placeholder="请设置价格"></el-input>
                          <!-- <div class="hui_inp_right"><div>平台服务费 0 元(收取2.8%费用)</div><div>[不含发票]</div></div> -->
                        </div>
						<div style="width:100%;height: 30px;"></div>
                        <!-- <div class="hui_jine">
                          <div class="hui_jine_one">保险金额</div>
                          <el-radio-group v-model="price" @change="selectinsurace">
                            <el-radio :disabled="order==1" :label="item.id" style="margin-top:10px;" v-for="(item,index) in insurancemsg" :key="index">{{item.detail}}</el-radio>
                          </el-radio-group>
                        </div> -->
                      </div>
                    </div>
                    
                  </el-form-item>
                   
                  <el-form-item>
                    <div style="display:flex;width:100%;align-items: center;margin-top:10px;">
                      <el-radio style="width:60px;" :label="1">询价</el-radio>
                      <div class="hui">
                        <div class="hui_xun">价格未知，需师傅报价，选择合适的师傅前托管资金到平台</div>
                        <div style="display:flex">
                          <el-input @keyup.native="keyups()" style="width:136px;height: 30px;" :disabled="order==0" v-model="form.price1" placeholder="期望价格"></el-input>
                          <div style="margin-left:8px;">期望价格方便师傅接单时进行参考</div>
                        </div>
                        <div style="width: 100%;height: 15px;"></div>
                        <!-- <div class="hui_aaa">(发布订单需支付9.9元发布费至平台，订单验收方可退回，若师傅报 后取消订单则不退发布费）</div> -->
                      </div>
                    </div>
                    
                  </el-form-item>
				  
				  <div style="width: 100%;height: 10px;"></div>
				  <el-form-item style="width:100%">
				    <div style="display:flex;width:100%;align-items: center;">
				      <!-- <el-radio style="width:60px;" :label="0">一口价</el-radio> -->
					  <div style="width: 90px;"></div>
				      <div class="hui">
				        
				        <div class="hui_jine">
				          <div class="hui_jine_one" style="margin-top: 0;">保险金额</div>
				          <el-radio-group v-model="price" @change="selectinsurace">
				            <el-radio  :label="item.id" style="margin-top:10px;" v-for="(item,index) in insurancemsg" :key="index">{{item.detail}}</el-radio>
				          </el-radio-group>
				        </div>
						<div style="width: 100%;height: 15px;"></div>
				      </div>
				    </div>
				    
				  </el-form-item>
				  
				  <div style="width: 100%;height: 10px;"></div>
				  <el-form-item style="width:100%">
				    <div style="display:flex;width:100%;align-items: center;">
				      <!-- <el-radio style="width:60px;" :label="0">一口价</el-radio> -->
				  					  <div style="width: 90px;"></div>
				      <div class="hui">
				        
				        <div class="hui_jine">
				          <!-- <div class="hui_jine_one" style="margin-top: 0;">保险金额</div> -->
				          <div class="hui_jine_1">平台服务费:{{interestRate}}元</div>
				        </div>
				      </div>
				    </div>
				    
				  </el-form-item>
				  
				  <!-- <div class="hui_jine_1">平台服务费:{{interestRate}}元</div> -->
				  
				  
            </el-radio-group>
            <span style="display: flex;justify-content: left;margin-top:30px" class="dialog-footer">
              总费用: {{order==0?form.price:form.price1}} + {{interestRate}} + {{insuranceVal}} = {{totalFee}} 元
            </span>
            <span style="display: flex;justify-content: center;margin-top:30px" class="dialog-footer">
              <el-button @click="releaseorder(0)">稍后发布</el-button>
              <el-button type="primary" @click="releaseorder(1)">确认发布</el-button>
            </span>
            <div class="fapiao">
              <img v-show="showfapiao==0" src="../../../assets/home/aaa.png" @click="showfapiao=1" alt="">
              <img v-show="showfapiao==1" src="../../../assets/home/bbb.png" @click="showfapiao=0" alt="">
              <div class="fapiao_one">
                  <div>需开发票</div>
                  <!-- <div>(收取一口价的8.8%服务费)</div> -->
              </div>
            </div>
          </el-dialog>
        </el-form-item>
            
            <!-- <el-form-item style="margin-left:53px;">
              <el-button type="primary">提交订单</el-button>
            </el-form-item> -->
          </el-form>
      </div>
  </div>
</template>

<script>
import { GetFaultTypes,GetAllBrand,Insurance,ReleaseWorkOrder,GetStoreByBrandId,EditEnterpriseCustomersById,GetCustomerService,getRepairOrderById,MyWorkOrder} from '../../../api/request';
import {imghost} from '../../../api/common';

import { regionData, CodeToText , TextToCode } from "element-china-area-data";
export default {
name:'release',
data(){
    return{
      orderNum:'',
      totalFee:0,   //订单总费用
      isServiceAccount:false, //是否为客服用户
      options: regionData,//省市区组件内容
      selectedOptions: [],//省市区组件选择后的内容
      radio: 3,
      order:0,
      show: false,
      price:0,
      dialogImageUrl: '',//选择图片的路径
      dialogVisible: false,//图片隐藏
      imgBaseUrl:imghost,
      ImageUrl:[],
      form: {
        faultone: '',//故障类型第一个选择框
        faulttypeid:'',//故障类型id
        describe:'',//故障类型描述
        brand:'',//服务品牌
        brandid:null,//选择的服务品牌id
        storeid:null,
        store:'',
        todoor:'',//上门时间
        province:'',//省
        city:'',//市
        area:'',//区
        detailAddress:'',//详细地址
        name:'',//联系人姓名
        phone:'',//联系人电话
        logistics:'',//物流类型
        expressnum:'',//快递单号
        expressphone:'',//发货人手机号
        remarks:'',//订单备注
        price:'',//一口价价格
		    price1:"", //询价
      },
      faultdata:'',//故障类型
      brandlist:'',//服务品牌列表
      storelist:'',
      insurancemsg:'',//保险信息
      insuranceVal:0, //保险金额
      showfapiao:0,
      id:'',//企业id
	  
	  
	  // 资料完善
	  user:{},
	  interestRate:0,
	  editid:"",
    }
},
created(){

	console.log(localStorage.getItem("editIPT"))

  this.id = Number(localStorage.getItem("id"))
  console.log(localStorage.getItem("id"))
  this.getfaulttype()
  this.getallbrand()
  this.getinsurance()
  
 
  this.user = JSON.parse(localStorage.getItem("msg"))
  this.isServiceAccount = this.user.isServiceAccount==1;
  
  console.log(this.user);
  console.log("这个");
  
  if(localStorage.getItem("editIPT")){
	  console.log("有值")
	  
	  var editIPT = JSON.parse(localStorage.getItem("editIPT"));
	  console.log(editIPT)
	  this.orderNum = editIPT.orderNum;
	  this.form.faulttypeid = editIPT.faultType;
	  // this.form.faulttypeid = editIPT.faultType;
	  this.form.describe = editIPT.detail;
	  this.form.name = editIPT.contact
	  this.form.phone = editIPT.contactPhoneNumber;
	  this.form.detailAddress = editIPT.address;
    if (editIPT.provinces && editIPT.city && editIPT.area){
      var provinceH = TextToCode[editIPT.provinces].code;
      var cityH = TextToCode[editIPT.provinces][editIPT.city].code;
      var AreaH = TextToCode[editIPT.provinces][editIPT.city][editIPT.area].code;
      this.selectedOptions =  [provinceH, cityH, AreaH,]
    }
	  this.form.province = editIPT.provinces
	  this.form.city = editIPT.city
	  this.form.area = editIPT.area
	  this.form.logistics = editIPT.logisticsCompany;
	  this.form.expressnum = editIPT.logisticsSingleNumber;
	  this.form.expressphone = editIPT.numberOfTheConsignor;
	  this.form.remarks = editIPT.orderNote;
	  
	  if(editIPT.genre == 0){ //一口价价格
		  this.form.price = editIPT.finalPrice;
		  this.order = 0;
		  this.interestRate = Math.round(editIPT.finalPrice * 0.20 * 100)/100;
	  }else if(editIPT.genre == 1){ //询价
		  this.form.price1 = editIPT.finalPrice;
		  this.order = 1
		  this.interestRate = Math.round(editIPT.finalPrice * 0.20 * 100)/100;
	  }
	  
	  if (editIPT.pic!=null && editIPT.pic!='') this.ImageUrl.push(editIPT.pic);
	  this.form.faultone = editIPT.faultTypeName;
	  this.form.faulttypeid = editIPT.faultType;
	  this.form.todoor = editIPT.doorOfTime;
	  this.form.brand = editIPT.brandName;
	  this.form.brandid = editIPT.brand;
	  this.getallstore(editIPT.brand);
	  var _this = this;
	  setTimeout(function(){
		  for(var i=0; i<_this.storelist.length; i++){
			  console.log(_this.storelist)
			console.log("延时")
			  if(_this.storelist[i].id == editIPT.storeId){
				  console.log(_this.storelist[i])
				  // console.log("延时")
				 _this.form.store = _this.storelist[i].storeName;
				   _this.form.storeid = editIPT.storeIde;
			  }
		  }
	  },1500)
	  
	  
	  // this.insurancemsg
	  
	  
	  this.price = editIPT.insuranceId
	  this.editid = editIPT.id
	  
	  // this.form.store = 
	  
  }else{
	  console.log("没值")
  }
  
  
  
},
methods:{
	
	
	keyup() {
		console.log(this.form.price)
    this.totalFee=0.00;
    if (this.judgeNull(this.form.price)) {
      this.$message("价格不能为空");
      this.interestRate = 0;
    }else{
      this.interestRate = Math.round(this.form.price * 0.20 *100)/100;
      this.totalFee= parseFloat(this.form.price) + parseFloat(this.interestRate) + this.insuranceVal;
    }	
	},
	
	keyups() {
		console.log(this.form.price1)
    this.totalFee=0.00;
    if (this.judgeNull(this.form.price1)) {
        this.$message("价格不能为空");
        this.interestRate = 0;
    }else{
      this.interestRate = Math.round(this.form.price1 * 0.20 *100)/100;
      this.totalFee= parseFloat(this.form.price1) + parseFloat(this.interestRate) + this.insuranceVal;
    }
    this.totalFee+=parseFloat(this.insuranceVal);	
	},
  //选择保险
  selectinsurace(e){
	  this.price = e;
    this.insuranceVal= 0; 
    this.insurancemsg.forEach(item=>{
      if (item.id == this.price) this.insuranceVal=item.money;
    });
    this.totalFee= parseFloat(this.order==0?this.form.price:this.form.price1) + parseFloat(this.interestRate) + this.insuranceVal;
  },

	//判空
	judgeNull(str) {
	    if (str == "") return true;
	    var regu = "^[ ]+$";
	    var re = new RegExp(regu);
	    return re.test(str);
	 },

  //选择门店
  selectstore(e){
    console.log(e)
    this.form.storeid = e
  },
  //获取所有门店
  getallstore(){
    if(this.form.brandid == ''){
      this.$message("请先选择服务品牌")
      return
    }
    var params={
      brandId:this.form.brandid
    }
    GetStoreByBrandId(params).then(res=>{
      console.log(res)
      if(res.data.code == 200){
        this.storelist = res.data.data
      }
    })
  },
  //发布
  releaseorder(val){
    if (val==0){
      this.show = false;
      return;
    }
    var imagestring = this.ImageUrl.toString()
    if(this.order == 0){
      if(this.form.price == ''){
        this.$message("请设置价格")
        return
      }
	  
      // if(this.price == 0){
      //   this.$message('请选择保险')
      //   return
      // }
	  
      var params = {
        enterpriseCustomersId:this.id,
        faultType:this.form.faulttypeid,  //故障类型
        faultDescription: this.form.describe,
        brand:this.form.brandid,  //服务品牌
        theDoorOfTime:this.form.todoor,
        province:this.form.province,
        city:this.form.city,
        area:this.form.area,
        detailAddress:this.form.detailAddress,
        concat:this.form.name,
        concatPhone:this.form.phone,
        logisticsCompany:this.form.logistics,
        logisticsSingleNumber:this.form.expressnum,
        numberOfTheConsignor:this.form.expressphone,
        orderNote:this.form.remarks,
        genre:this.order,
        finalPrice:this.form.price,
        insuranceId:this.price,
        isInvoice:this.showfapiao,
        isRelease:val,
        pic:imagestring,
        storeId:this.form.storeid,
		malfunctionId:this.editid,
      }
    }else{
		
		if(this.form.price1 == ''){
		  this.$message("请设置询价价格")
		  return
		}
		
      var params = {
        enterpriseCustomersId:this.id,
        faultType:this.form.faulttypeid, //故障类型
        faultDescription: this.form.describe,
        brand:this.form.brandid,  //服务品牌
        theDoorOfTime:this.form.todoor,
        province:this.form.province,
        city:this.form.city,
        area:this.form.area,
        detailAddress:this.form.detailAddress,
        concat:this.form.name,
        concatPhone:this.form.phone,
        logisticsCompany:this.form.logistics,
        logisticsSingleNumber:this.form.expressnum,
        numberOfTheConsignor:this.form.expressphone,
        orderNote:this.form.remarks,
        genre:this.order,
        isInvoice:this.showfapiao,
        finalPrice:this.form.price1,
        isRelease:val,
        pic:imagestring,
        storeId:this.form.storeid,
		insuranceId:this.price,
		editid:this.editid,
		malfunctionId:this.editid,
      }
    }
    console.log(params)
    ReleaseWorkOrder(params).then(res=>{
      console.log(res)
      
	  
	  
	  
	  
      if(res.data.code == 200){
        this.show = false
        this.$message(res.data.msg)
		    this.getRepairOrderById()
        //发布成功后跳转
        var params = {
              enterpriseCustomersId:this.id,
              orderNum:this.orderNum,
              pageNum:1, 
              pageSize:9,
              type:null
            }
        MyWorkOrder(params).then(res=>{ 
          this.$router.push({
            path: "/Orderdetails",
            name: "Orderdetails", // 要跳转的路径的 name,可在 router 文件夹下的 index.js 文件内找
            query: {msg:res.data.data[0]}
          })
        });   
      }else{
        this.$message(res.data.data)
      }
    })

  },
  
  
    getRepairOrderById(){
		var params = {
			id:this.id 
		}
		getRepairOrderById(params).then(res=>{
		  console.log(res)
		  if(res.data.code == 200){
		    localStorage.setItem("banlance",JSON.stringify(res.data.data.banlance));
		  }else{
		    this.$message(res.data.data)
		  }
		})
	},
  
  
  
  
  //提交订单
  submitOrder(){
	  if(this.user.contactPersonnel == null && this.user.contactPersonnelPhone == null && this.user.postcode == null && this.user.personalAddress == null ){
		    this.$message("请先前往个人信息完善个人资料")
	  } else{
      let errMsg="";
      if (this.form.faulttypeid=='') errMsg+="请选择工单类型.<br>";
      if (this.form.describe=='') errMsg+="请填写工单描述.<br>";
      if (this.form.todoor==null || this.form.todoor=="") errMsg+="请选择上门时间.<br>";
      if (this.form.detailAddress=="") errMsg+="请选择上门地址.<br>";
      if (this.form.name=='') errMsg+="请填写联系人.<br>";
      if (this.form.phone=='') errMsg+="请选择联系电话.<br>";
		  if(errMsg!=""){
		    this.$message({
          dangerouslyUseHTMLString: true,
          showClose: true,
          message:errMsg,
          type: 'error',
          duration: 1000
        });
		    return
		  }
      if (this.isServiceAccount && (this.form.brandid==""||this.form.store=="")){
		    this.$message("请选择品牌及门店")
		    return
      }
		  this.show = true
	  }
	  
    // console.log(this.form.brandid)
   
  },
  //切换发布方式
  selelctreleasetype(){
	  
	 this.interestRate = 0;
    console.log(this.order)
    if(this.order == 1){
      this.form.price = '',
      this.price=""
    }else if(this.order == 0){
		this.price= ""
		this.form.price1 = ''
	}
  },
  //获取保险信息
  getinsurance(){
    var params = {}
    Insurance(params).then(res=>{
      console.log(res)
      if(res.data.code == 200){
        this.insurancemsg = res.data.data
      }
    })
  },
  //选择省市区
  handleChange(e) {
    for (let i = 0; i < this.selectedOptions.length; i++) {
      if(i==0){
        this.form.province = CodeToText[this.selectedOptions[i]]
      }
      if(i==1){
        this.form.city = CodeToText[this.selectedOptions[i]]
      }
      if(i==2){
        this.form.area = CodeToText[this.selectedOptions[i]]
      }
	   
	   console.log(this.selectedOptions)
    }
  },
  //获取所有品牌
  getallbrand(){
    var params = {}
    GetAllBrand(params).then(res=>{
      console.log(res)
      if(res.data.code == 200){
        // for(var i=0;i<res.data.data.length;i++){
        //   res.data.data[i].
        // }
        this.brandlist = res.data.data
        console.log(this.brandlist)
      }
    })
  },
  //选择品牌id
  selectbrand(e){
    // console.log(e)
    this.form.brandid = e
    this.storelist=''
    this.form.store = ''
    this.getallstore()
    // this.form.brand
  },
  //获取故障类型
  getfaulttype(){
    var params = {

    }
    GetFaultTypes(params).then(res=>{
      console.log(res)
      if(res.data.code == 200){
        this.faultdata = res.data.data
      }
    })
  },
  //选择故障类型
  selectfaulttype(e){
    this.form.faulttypeid = e
  },
  //选择上门时间
  selecttodoortime(e){
    console.log(this.form.todoor)
  },
  //关闭弹窗
  handleClose(done) {
    this.$confirm('确认关闭？')
      .then(_ => {
        done();
      })
      .catch(_ => {});
  },
  handleAvatarSuccess(res, file){
	  console.log(res)
    this.ImageUrl = this.ImageUrl.concat(res)
	console.log(this.ImageUrl)
  },
  beforeAvatarUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    const isLt2M = file.type === 'image/png';
    if (isJPG==false && isLt2M==false) {
      this.$message.error('上传文件需为图片');
    }
    return isJPG || isLt2M;
  },
},

destroyed(){
	localStorage.removeItem("editIPT")
},
 



}
</script>

<style scoped>
	
	.sfff{
		width: 100%;
		height: 80px;
		/* background: #02AAEF; */
		text-indent: 65px;
		line-height: 80px;
		font-size: 16px;
	}
.release{
    width: 100%;
    /* overflow-y:scroll */
    
}
.release_top{
    padding-top: 23px;
    padding-bottom: 30px;
    
} 
.release_center{
    width: 100%;
    height: 10px;
    background-color: #F5F5F5;
    margin-bottom: 20px;
}
.hui{
    width: 75%;
    background-color: #F8F8F8;
    box-sizing: border-box;
    padding: 8px 0px 8px 35px;
}
.hui_title{
    font-size: 14px;
}
.hui_inp{
    display: flex;
}
.hui_inp_right{
    display: flex;
    margin-left: 6px;
}
.hui_inp_right div:first-child{
    font-size: 14px;
}
.hui_inp_right div:last-child{
    font-size: 14px;
    color: #FF4545;
}
.hui_jine_one{
    margin-top: 15px;
    font-size: 14px;
}
.hui_xun{
    font-size: 14px;
}
.hui_aaa{
    font-size: 14px;
    color:#2388FA;
}
.fapiao{
    display: none;
    align-items: center;
    justify-content: center;
}
.fapiao img{
    margin-right: 6px;
    width: 17px;
    height: 17px;
    cursor: pointer;
    /* background-color: red; */
}
.fapiao_one{
    display: flex;
}
.fapiao_one div:first-child{
    font-size: 14px;
}
.fapiao_one div:last-child{
    font-size: 14px;
    /* color: #FE4444; */
}
.shangmen{
  display: flex;
}
.imggg{
  display: flex;
}
.imggg img{
  margin-right: 14px;
  width: 126px;
  height: 126px;
  border-radius: 10px;
}
.avatar-uploader{
    width: 126px;
    height: 126px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload {
    border: 1px solid #cccccc;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 126px;
    height: 126px;
    line-height: 126px;
    text-align: center;
}
.store{
  display: flex;
}

.hui_jine_1{
	width: 100%;
	height: 40px;
	/* background: #35406E; */
	line-height: 40px;
	/* text-indent: 100px; */
}
</style>