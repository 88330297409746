import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login.vue'
import Register from '../views/login/Register.vue'
import Home from '../views/home/Home.vue'
import Release from '../views/homeassembly/release/release.vue'
import Rightrecharge from '../views/homeassembly/rightrecharge/rightrecharge.vue'
import Myworkorder from '../views/homeassembly/myworkorder/myworkorder.vue'
import Teacher from '../views/homeassembly/teacher/teacher.vue'
import Details from '../views/homeassembly/teacher/details.vue'
import Myself from '../views/homeassembly/myself/myself.vue'
import Invoice from '../views/homeassembly/invoice/invoice.vue'
import Apply from '../views/homeassembly/invoice/apply.vue'
import Information from '../views/homeassembly/invoice/information.vue'
import Invoicerecord from '../views/homeassembly/invoice/invoicerecord.vue'
import Invoicedetails from '../views/homeassembly/invoice/invoicedetails.vue'
import Orderdetails from '../views/homeassembly/myworkorder/orderdetails.vue'
import UserAgreement from '../views/login/UserAgreement.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register
  },
  {
    path: '/UserAgreement',
    name: 'UserAgreement',
    component: UserAgreement
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    redirect:'/Release',
    meta: {
      keepAlive: true
    },
    children:[
      {
        path: '/Release',
        name: 'Release',
        component: Release,
      },
      {
        path: '/Myworkorder', 
        name: 'Myworkorder',
        component: Myworkorder,
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/Orderdetails', 
        name: 'Orderdetails',
        component: Orderdetails,
      },
      {
        path: '/Rightrecharge',
        name: 'Rightrecharge',
        component: Rightrecharge,
      },
      {
        path: '/Teacher',
        name: 'Teacher',
        component: Teacher,
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/Details', 
        name: 'Details',
        component: Details,
      },
      {
        path: '/Myself', 
        name: 'Myself',
        component: Myself,
      },
      {
        path: '/Invoice', 
        name: 'Invoice',
        component: Invoice,
        redirect:'/Invoice/Apply',
        children:[
          {
            path: '/Invoice/Apply', 
            name: 'Apply',
            component: Apply,
          },
          {
            path: '/Invoice/Information', 
            name: 'Information',
            component: Information,
          },
          {
            path: '/Invoice/Invoicerecord', 
            name: 'Invoicerecord',
            component: Invoicerecord,
          }
        ]
      },
      {
        path: '/Invoicedetails', 
        name: 'Invoicedetails',
        component: Invoicedetails,
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
