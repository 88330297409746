<template>
  <div class="invoice">
    <!-- 头部导航 -->
    <div class="invoice_list">
      <div class="recharge_top">
        <div class="recharge_top_left">
          <div class="top_left_list" @click="selectbar(1)">
            <div :class="bar == 1 ? 'select_list' : ''">申请开票</div>
            <div v-show="bar == 1"></div>
          </div>
          <div class="top_left_list" @click="selectbar(2)">
            <div :class="bar == 2 ? 'select_list' : ''">发票信息</div>
            <div v-show="bar == 2"></div>
          </div>
          <div class="top_left_list" @click="selectbar(3)">
            <div :class="bar == 3 ? 'select_list' : ''">发票记录</div>
            <div v-show="bar == 3"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 头部导航 -->
    <div class="release">
        <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Invoice",
  data(){
      return{
          bar:1
      }
  },
  created(){
      if(this.$router.history.current.name == 'InformApplyation'){
        this.bar = 1
        }
      if(this.$router.history.current.name == 'Information'){
        this.bar = 2
        }
        if(this.$router.history.current.name == 'Invoicerecord'){
        this.bar = 3
        }
  },
  methods:{
      selectbar(index){
          if(this.bar == index){
              return
          }
          this.bar = index
          if(index==1){
              this.$router.push('/Invoice/Apply')
          }
          if(index==2){
              this.$router.push('/Invoice/Information')
          }
          if(index==3){
              this.$router.push('/Invoice/Invoicerecord')
          }
          
      }
  }
};
</script>

<style scoped>
.invoice_list {
  /* height: 60px; */
  display: flex;
  /* border-bottom: 1px solid #ededed; */
}
.list_one div:first-child {
  height: 56px;
  line-height: 56px;
  font-size: 16px;
}
.recharge_top {
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
}
.recharge_top_left {
  display: flex;
  justify-content: flex-start;
}
.top_left_list {
  margin-right: 60px;
  cursor: pointer;
}
.top_left_list div:first-child {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
}
.top_left_list div:last-child {
  height: 4px;
  width: 100%;
  background-color: #4a9bec;
}
.select_list {
  color: #4a9bec;
}
.release{
    width: 100%;
}
</style>