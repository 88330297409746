<template>
  <div class="infor">
      <div class="infor_list">
          <div>发票抬头</div>
          <input type="text" placeholder="请输入发票抬头" :disabled="inpdisabled" v-model="msg.invoiceTitle">
      </div>
      <div class="infor_list">
          <div>开户行</div>
          <input type="text" placeholder="请输入开户行" :disabled="inpdisabled" v-model="msg.openingBank">
      </div>
      <div class="infor_list">
          <div>银行账号</div>
          <input type="text" placeholder="请输入银行账号" :disabled="inpdisabled" v-model="msg.bankAccount">
      </div>
      <div class="infor_list">
          <div>纳税人识别号</div>
          <input type="text" placeholder="请输入纳税人识别号" :disabled="inpdisabled" v-model="msg.taxpayerIdentificationNumber"> 
      </div>
      <div class="infor_list">
          <div>公司地址</div>
          <input type="text" placeholder="请输入公司地址" :disabled="inpdisabled" v-model="msg.companyAddress">
      </div>
      <div class="infor_list">
          <div>公司电话</div>
          <input type="text" placeholder="请输入公司电话" :disabled="inpdisabled" v-model="msg.phoneCompany">
      </div>
      <div class="adit" v-show="inpdisabled==true" @click="edit(false)">编辑</div>
      <div class="adit" v-show="inpdisabled==false" @click="edit(true)">完成</div>
  </div>
</template>

<script>
import { EditEnterpriseCustomersById } from '../../../api/request';
export default {
name:'Information',
data(){
    return{
        inpdisabled:true,
        msgs:'',
        msg:{
            id:'',
            invoiceTitle:'',
            openingBank:'',
            bankAccount:'',
            taxpayerIdentificationNumber:'',
            companyAddress:'',
            phoneCompany:'',
        }
    }
},
created(){
    this.msg.id = Number(localStorage.getItem("id")) 
    this.getmsg()
},
methods:{
    edit(type){
        if(type==false){
            this.inpdisabled = false
        }else{
            var params = this.msg  
            EditEnterpriseCustomersById(params).then(res=>{
                console.log(res)
                this.$message(res.data.msg);
                if(res.data.code == 200){
                    
                    this.msgs.invoiceTitle = this.msg.invoiceTitle
                    this.msgs.openingBank = this.msg.openingBank
                    this.msgs.bankAccount = this.msg.bankAccount
                    this.msgs.taxpayerIdentificationNumber = this.msg.taxpayerIdentificationNumber
                    this.msgs.companyAddress = this.msg.companyAddress
                    this.msgs.phoneCompany = this.msg.phoneCompany
                    localStorage.setItem("msg",JSON.stringify(this.msgs))
                    this.inpdisabled = true
                }
            })
        }
    },
    //获取发票信息
    getmsg(){
        this.msgs = JSON.parse(localStorage.getItem("msg"))
        this.msg.invoiceTitle = this.msgs.invoiceTitle
        this.msg.openingBank = this.msgs.openingBank
        this.msg.bankAccount = this.msgs.bankAccount
        this.msg.taxpayerIdentificationNumber = this.msgs.taxpayerIdentificationNumber
        this.msg.companyAddress = this.msgs.companyAddress
        this.msg.phoneCompany = this.msgs.phoneCompany
    }
}
}
</script>

<style scoped>
.infor{
    padding-top: 40px;
}
.infor_list{
    line-height: 40px;
    display: flex;
}
.infor_list div{
    margin-right: 20px;
    width: 160px;
    text-align: right;
    font-size: 14px;
}
.infor_list input{
    font-size: 14px;
    background-color: #FFFFFF;
}
.adit{
    cursor: pointer;
    margin-left: 300px;
    margin-top: 30px;
    font-size: 16px;
    color: #4A9BEC;
}
</style>