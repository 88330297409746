<template>
  <div class="teacher" >
    <div v-show="show == 1">
        <el-form ref="form" :model="form" label-width="80px">
        <div class="form_fle">
            <el-form-item label="师傅名字" style="margin-right: 20px">
            <el-input v-model="form.masterName" placeholder="填写师傅名称"></el-input>
            </el-form-item>
            <el-form-item label="施工地区" style="margin-right: 20px">
              <el-cascader
                size="large"
                :options="options"
                v-model="selectedOptions"
                @change="handleChange"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="手机号码" style="margin-right: 20px">
              <el-input
                  v-model="form.phone"
                  placeholder="请输入师傅手机号"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="业务类型" style="margin-right: 20px">
            <el-select v-model="form.region" placeholder="点击选择">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
            </el-select>
            </el-form-item> -->
        </div>
        <div class="form_btn">
            <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button type="success" @click="resetting">重置</el-button>
            </el-form-item>
        </div>
        </el-form>
        <div class="tea_list">
        <el-table :data="tableData" stripe style="width: 100%" height="620" :header-cell-style="{textAlign: 'center',backgroundColor:'#FAFAFA'}" :cell-style="{ textAlign: 'center' }">
            <el-table-column prop="headPic" label="师傅头像" width="180">
              <template slot-scope="scope">
                <el-image class="head_pic" :src="scope.row.headPic" fit="cover" ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="师傅名字" width="180" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="phone" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
            <!-- <el-table-column prop="evaluate" label="好评率"> </el-table-column> -->
            <el-table-column prop="takeOrderSize" label="接单量" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="skill" label="技能证" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="region" label="服务地区" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="" label="管理">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        </div>
        <div class="fenye" v-if="pageshow">
            <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage4" 
            :page-size="7"
            layout="total, prev, pager, next"
            :total="totalnum">
            </el-pagination>
        </div>
    </div>
    <!-- <Details class="release" v-if="show !=1" /> -->
  </div>
</template>  

<script>
import { FindMaster } from '../../../api/request';
import { imghost } from '../../../api/common';
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      imgBaseUrl:imghost,
      options: regionData,//省市区组件内容
      selectedOptions: [],//省市区组件选择后的内容
      show:1,//控制详情页组件页面显示
      currentPage4: 1,
      totalnum:0,//全部数量
      form: { //表单内容
        masterName: "",
        phone: "",
      },
      provinces:'',//省
      city:'',//市
      area:'',//区
      tableData: [],
      pageshow:true
    };
  },
  created(){
    this.getlist()
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == "/Details") {
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
    }
    next();
  },
  methods: {
    //获取所有师傅
    getlist(){
      var params = {
        masterName:this.form.masterName,
        provinces:this.provinces,
        city:this.city,
        area:this.area,
        phone:this.form.phone,
        pageNum:1,
        pageSize:7
      }
      FindMaster(params).then(res=>{
        if(res.data.code == 200){
          this.totalnum = res.data.count
          var datalist = res.data.data
          for(var i=0;i<datalist.length;i++){
            if(datalist[i].headPic != null && datalist[i].headPic!=''){
              datalist[i].headPic = this.imgBaseUrl+datalist[i].headPic
            }
            if(datalist[i].isElectricianAudit == 0){
              datalist[i].skill = ''
            }else{
              datalist[i].skill = '电工证'
            }
            datalist[i].region = datalist[i].province+datalist[i].city+datalist[i].area
          }
          this.tableData = datalist
          console.log(this.tableData)
        }
      })
    },
    //选择省市区
    handleChange(e) {
      for (let i = 0; i < this.selectedOptions.length; i++) {
        if(i==0){
          this.provinces = CodeToText[this.selectedOptions[i]]
        }
        if(i==1){
          this.city = CodeToText[this.selectedOptions[i]]
        }
        if(i==2){
          this.area = CodeToText[this.selectedOptions[i]]
        }
      }
      console.log(this.provinces,this.city,this.area);
    },
    //进入到详情页
    handleClick(e){
      console.log(e)
      this.$router.push({
          path: "/Details",
          name: "Details", // 要跳转的路径的 name,可在 router 文件夹下的 index.js 文件内找
          query: {msg:e}
        })
    },
    //重置
    resetting(){
      this.form.masterName = ''
      this.form.phone = ''
      this.provinces = ''
      this.city = ''
      this.area = ''
      this.selectedOptions = []
      this.tableData = []
      this.totalnum = 0
      this.currentPage4 = 1
      this.pageshow = false;//让分页隐藏
      this.$nextTick(() => {//重新渲染分页
          this.pageshow = true;
      });
      this.getlist()
    },
    //查询师傅
    onSubmit() {
      if(this.form.masterName=='' && this.provinces=='' && this.city=='' && this.area=='' && this.form.phone==''){
        this.$message('请输入查询条件')
        return
      }
      var params = {
        masterName:this.form.masterName,
        provinces:this.provinces,
        city:this.city,
        area:this.area,
        phone:this.form.phone,
        pageNum:1,
        pageSize:7
      }
      console.log(params)
      FindMaster(params).then(res=>{
        console.log(res)
        if(res.data.code == 200){
          this.totalnum = res.data.count
          var datalist = res.data.data
          for(var i=0;i<datalist.length;i++){
            datalist[i].headPic = imgBaseUrl+datalist[i].headPic
            if(datalist[i].isElectricianAudit == 0){
              datalist[i].skill = ''
            }else{
              datalist[i].skill = '电工证'
            }
            datalist[i].region = datalist[i].province+datalist[i].city+datalist[i].area
          }
          this.tableData = datalist
        }
      })
    },
    //切换分页页码
    handleCurrentChange(val) {
      var params = {
        masterName:this.form.masterName,
        provinces:this.provinces,
        city:this.city,
        area:this.area,
        phone:this.form.phone,
        pageNum:val,
        pageSize:7
      }
      FindMaster(params).then(res=>{
        console.log(res)
        if(res.data.code == 200){
          var datalist = res.data.data
          for(var i=0;i<datalist.length;i++){
            datalist[i].headPic = imgBaseUrl+datalist[i].headPic
            if(datalist[i].isElectricianAudit == 0){
              datalist[i].skill = ''
            }else{
              datalist[i].skill = '电工证'
            }
            datalist[i].region = datalist[i].province+datalist[i].city+datalist[i].area
          }
          this.tableData = datalist
        }
      })
    }
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}
.teacher {
  padding-top: 23px;
}
.form_fle {
  display: flex;
}
.form_btn {
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
}
.chakan{
    color: #3290EF;
    cursor: pointer;
}
.head_pic{
    width: 50px;
    height: 50px;
}
.fenye{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
</style>