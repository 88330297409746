<template>
  <div>
      <div class="back" @click="back">返回上一级</div>
      <div class="invoice">
          <div class="invoice_title">发票信息</div>
          <div class="invoice_con">
              <div class="invoice_con_list">
                  <div>发票抬头:</div>
                  <div>{{msg.invoiceIssuingEntity}}</div>
              </div>
              <div class="invoice_con_list">
                  <div>纳税人识别号:</div>
                  <div>{{taxpayerIdentificationNumber}}</div>
              </div>
              <div class="invoice_con_list">
                  <div>开票类型:</div>
                  <div>{{msg.theTypeOfMakeOutAnInvoice}}</div>
              </div>
              <div class="invoice_con_list">
                  <div>开票内容:</div>
                  <div>{{msg.contentOfMakeOutAnInvoice}}</div>
              </div>
              <div class="invoice_con_list">
                  <div>开票金额:</div>
                  <div>{{msg.invoiceAmount}}元</div>
              </div>
          </div>
      </div>
      <div class="invoice">
          <div class="invoice_title">邮寄信息</div>
          <div class="invoice_con">
              <div class="invoice_con_list">
                  <div>收件人:</div>
                  <div>{{msg.recipient}}</div>
              </div>
              <div class="invoice_con_list">
                  <div>联系电话:</div>
                  <div>{{msg.recipientContactNumber}}</div>
              </div>
              <div class="invoice_con_list">
                  <div>邮政编码:</div>
                  <div>{{msg.postalCode}}</div>
              </div>
              <div class="invoice_con_list">   
                  <div>邮寄地址:</div>
                  <div>{{msg.mailingAddress}}</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
name:'Invoicedetails',
data(){
    return{
        msg:'',
        taxpayerIdentificationNumber:''
    }
},
created(){
    this.msg = this.$route.query.msg
    this.taxpayerIdentificationNumber = JSON.parse(localStorage.getItem("msg")).taxpayerIdentificationNumber
},
methods:{
    //返回上一路由
    back(){
        this.$router.go(-1)
    }
}
}
</script>

<style scoped>
.back{
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    padding-right: 30px;
    text-align: right;
}
.invoice{
    padding-left: 13px;
    padding-right: 13px;
    margin-bottom: 18px;
}
.invoice_title{
    height: 40px;
    background-color: #F5F5F5;
    line-height: 40px;
    font-size: 14px;
    padding-left: 47px;
}
.invoice_con_list{
    display: flex;
    line-height: 45px;
}
.invoice_con_list div:first-child{
    width: 135px;
    text-align: right;
    margin-right: 22px;
    font-size: 14px;
}
.invoice_con_list div:last-child{
    font-size: 14px;
}
</style>