<template>
  <div class="details">
    <div class="details_title">
      <div>师傅详情</div>
      <div @click="back">返回上一级</div>
    </div>
    <div class="details_btm">
      <div class="msg_list">
        <div class="list_left">师傅头像：</div>
        <img class="av" :src="msg.headPic" alt="加载失败" />
      </div>
      <div class="msg_list">
        <div class="list_left">师傅名字：</div>
        <div class="list_right">{{msg.name}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">联系方式：</div>
        <div class="list_right">{{msg.phone}}</div>
      </div>
      <div class="msg_list">
        <div class="list_left">接单数量：</div>
        <div class="list_right">{{msg.takeOrderSize}}</div>
      </div>
      <!-- <div class="msg_list">
        <div class="list_left">综合评分：</div>
        <div class="list_right">1111</div>
      </div> -->
      <div class="msg_list">
        <div class="list_left">服务区域：</div>
        <div class="list_right">{{msg.province}}{{msg.city}}{{msg.area}}</div>
      </div>
      <!-- <div class="msg_list">
        <div class="list_left">服务业务：</div>
        <div class="list_right">33333</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Details",
  data() {
    return {
      msg:''
    };
  },
  created() {
    console.log(this.$route.query.msg);
    this.msg = this.$route.query.msg
  },
  // beforeRouteEnter(to, from, next) {
  //   // console.log(to)
  //   // console.log(from)
  //   if (from.path == "/Teacher") {
  //     from.meta.keepAlive = true;
  //   } else {
  //     from.meta.keepAlive = false;
  //   }
  //   next();
  // },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.details_title {
  height: 40px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
}
.details_title div:last-child {
  cursor: pointer;
}
.details_btm {
}
.msg_list {
  margin-top: 14px;
  display: flex;
}
.list_left {
  width: 150px;
  text-align: right;
  font-size: 14px;
}
.av {
  width: 50px;
  height: 50px;
}
.list_right {
  font-size: 14px;
}
</style>