<template>
  <div class="record">
      <el-table class="tablebox" :data="tableData" stripe style="width: 100%" height="676"  :header-cell-style="{textAlign: 'center',backgroundColor:'#FAFAFA'}" :cell-style="{ textAlign: 'center' }">
          <el-table-column prop="createTime" label="申请开票日期" width="180">
          </el-table-column>
          <el-table-column prop="contentOfMakeOutAnInvoice" label="开票内容" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="invoiceIssuingEntity" label="开票单位" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="theTypeOfMakeOutAnInvoice" label="开票类型" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="invoiceAmount" label="发票金额" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="singularOfMakeOutAnInvoice" label="开票单数" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="states" label="发票状态" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="" label="管理">
              <!-- <div class="chakan" @click="see">查看</div> -->
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
              </template>
          </el-table-column>
      </el-table>
        <!-- 分页 -->
        <div class="fenye">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage4" 
            :page-size="11"
            layout="total, prev, pager, next"
            :total="totalnum">
          </el-pagination>
        </div>
        <!-- 分页 -->
  </div> 
</template>

<script>
import { RecordsOfMakeOutAnInvoice } from '../../../api/request';
export default {
name:"Invoicerecord",
data(){
    return{
      id:'',
      totalnum:0,
      currentPage4:1,
      tableData: [],
    }
},
created(){
  // localStorage.getItem("id")
  if(localStorage.getItem("id")){
    this.id =Number(localStorage.getItem("id")) 
    this.getinvoicelist()
  }
  
},
methods:{
    //获取第一页开票记录
    getinvoicelist(){
      var params = {
        pageNum:1,
        pageSize:11,    
        enterpriseCustomersId:this.id
      }
      console.log(params)
      RecordsOfMakeOutAnInvoice(params).then(res=>{
        console.log(res)
        if(res.data.code == 200){
          this.totalnum = res.data.count
          var datalist = res.data.data
          for(var i=0;i<datalist.length;i++){
            if(datalist[i].invoiceState == 0){
              datalist[i].states = '待邮寄'
            }else{
              datalist[i].states = '已邮寄'
            }
          }
          this.tableData = datalist
        }
      })
    },
    //获取分页数据
    handleCurrentChange(val){
      var params = {
        pageNum:val,
        pageSize:11,    
        enterpriseCustomersId:this.id
      }
      RecordsOfMakeOutAnInvoice(params).then(res=>{
        console.log(res)
        if(res.data.code == 200){
          var datalist = res.data.data
          for(var i=0;i<datalist.length;i++){
            if(datalist[i].invoiceState == 0){
              datalist[i].states = '待邮寄'
            }else{
              datalist[i].states = '已邮寄'
            }
          }
          this.tableData = datalist
        }
      })
    },
    //查看发票记录详情
    handleClick(e){
      // console.log(e)
      this.$router.push({
        path: "/Invoicedetails",
        name: "Invoicedetails", // 要跳转的路径的 name,可在 router 文件夹下的 index.js 文件内找
        query: {msg:e}
      })
    }
}
}
</script>

<style scoped>
.record{
    padding-top: 35px;
}
.chakan{
    color: #3290EF;
    cursor: pointer;
}
.fenye{
    margin-top: 14px;
    display: flex;
    justify-content: center;
}
</style>