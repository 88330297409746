<template>
  <div class="apply">
    <!-- 头部 -->
    <div class="apply_top">
      <div class="top_left">
        <div class="top_list_one">
          <div>开始时间</div>
          <el-date-picker
            v-model="starttime"
            type="date"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <div class="top_list_one">
          <div>结束时间</div>
          <el-date-picker
            v-model="endtime"
            type="date"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
      </div>
      <div class="top_right">
        <div @click="query">查询</div>
        <div @click="kaipiao">开票</div>
      </div>
    </div>
    <!-- 头部 -->
    <div class="cen_list">
      <el-table
        class= "table-style"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        height="577"
        stripe
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#FAFAFA' }"
        :cell-style="{ textAlign: 'center' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="编号"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="orderNum" label="订单编号"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="faultTypeName" label="故障类型"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="constructionaddress" label="施工地址"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="brandName" label="品牌名称"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="createTime" label="施工开始时间"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="submitTime" label="施工结束时间"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="masterPrice" label="费用"  :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="fenye" v-if="pageshow">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage" 
        :page-size="11"
        layout="total, prev, pager, next"
        :total="totalnum">
      </el-pagination>
    </div>
    <!-- 分页 -->
    <!-- 弹窗 -->
      <el-dialog
            title="信息确认"
            :visible.sync="show"
            width="30%"
            center
            :show-close="false">           
            <div class="dialog_con">
                <div class="dialog_con_list">
                    <div class="dialog_left">已选订单</div>
                    <div class="dialog_right">{{selectmsg.id}}</div>
                </div>
                <div class="dialog_con_list">
                    <div class="dialog_left">开票总金额</div>
                    <div class="dialog_right">{{selectmsg.masterPrice}}</div>
                </div>
                <div class="dialog_con_list">
                    <div class="dialog_left">发票抬头</div>
                    <div class="dialog_right">{{msgs.invoiceTitle}}</div>     
                </div>
                <!-- <div class="dialog_con_list">
                    <div class="dialog_left">纳税人识别号</div>
                    <div class="dialog_right">{{msgs.taxpayerIdentificationNumber}}</div>
                </div> -->
                <div class="dialog_con_list">
                    <div class="dialog_left">开票内容</div>
                    <div>
                        <input class="dialog_right_inp" type="text" placeholder="请输入开票内容" v-model="billingcontent">
                    </div>
                </div>
                <div class="dialog_con_list mar_top">
                    <div class="dialog_left">收件人</div> 
                    <div><input class="dialog_right_inp" type="text" :disabled="inpdisabled" placeholder="请输入收件人名称" v-model="selectmsg.contact"></div>
                </div>
                <div class="dialog_con_list">
                    <div class="dialog_left">收件人联系电话</div>
                    <div>
                        <input class="dialog_right_inp" type="text" :disabled="inpdisabled" placeholder="请输入收件人联系电话" v-model="selectmsg.contactPhoneNumber">
                    </div>
                </div>
                <div class="dialog_con_list">
                    <div class="dialog_left">邮寄地址</div>
                    <div><input class="dialog_right_inp" type="text" :disabled="inpdisabled" placeholder="请输入邮寄地址" v-model="selectmsg.constructionaddress"></div>
                </div>
                <div class="dialog_con_list">
                    <div class="dialog_left">邮寄编码</div>
                    <div><input class="dialog_right_inp" type="text" :disabled="inpdisabled" placeholder="请输入邮寄编码" v-model="postalCode"></div>
                </div>
            </div>
            <div class="adit" v-show="inpdisabled==true" @click="inpdisabled=false">修改邮寄信息</div>
            <div class="adit" v-show="inpdisabled==false" @click="inpdisabled=true">完成</div>
            <div class="shegnqing" @click="application">确认申请</div>
        </el-dialog>
      <!-- 弹窗 -->
  </div>
</template>

<script>
import { UninvoicedWorkOrder,ConfirmMakeOutAnInvoice } from '../../../api/request';
export default {
  name: "Apply",
  data() {
    return {
      starttime: null,//开始时间
      endtime: null,//结束时间
      currentPage: 1,//分页当前页
      totalnum:0,
      show: false,//弹窗显示和隐藏
      region:'',//选择开票内容
      inpdisabled:true,//控制邮寄信息的可修改性
      tableData: [],
      multipleSelection: [],
      id:'',//企业id
      selectmsg:'',
      msgs:'',//发票抬头和纳税人编号
      billingcontent:'',//申请时的开票内容
      postalCode:'',//申请时的邮政编码
      pageshow:true,
    };
  },
  created(){
    if(localStorage.getItem("id")){
      this.id =Number(localStorage.getItem("id")) 
      this.getapplymsg() 
      this.getmsg()    
    }
	
	
	
	
	
  },
  methods: {
    //获取发票信息
    getmsg(){
        this.msgs = JSON.parse(localStorage.getItem("msg"));
		console.log(this.msgs)
    },
    //查询
    query(){
      console.log(this.starttime,this.endtime)
      if(this.starttime==null || this.endtime==null){
        this.getapplymsg()
      }else{
        var params = {
          pageNum:1,
          pageSize:11,
          enterpriseCustomersId:this.id,
          beginTime:this.starttime,
          endTime:this.endtime
        }
        UninvoicedWorkOrder(params).then(res=>{
          console.log(res)
          if(res.data.code == 200){
            this.currentPage = 1
            this.totalnum = res.data.count
            var datalist = res.data.data
            for(var i=0;i<datalist.length;i++){
              datalist[i].constructionaddress = datalist[i].provinces+datalist[i].city+datalist[i].area+datalist[i].address
            }
            this.tableData = datalist
            this.pageshow = false;//让分页隐藏
            this.$nextTick(() => {//重新渲染分页
                this.pageshow = true;
            });
          }
        })
      }
    },
    //获取申请开票信息
    getapplymsg(){
      this.multipleSelection = []
      var params = {
        pageNum:1,
        pageSize:11,
        enterpriseCustomersId:this.id
      }
      UninvoicedWorkOrder(params).then(res=>{
        console.log(res)
        if(res.data.code == 200){
          this.totalnum = res.data.count
          var datalist = res.data.data
          for(var i=0;i<datalist.length;i++){
            datalist[i].constructionaddress = datalist[i].provinces+datalist[i].city+datalist[i].area+datalist[i].address
          }
          this.tableData = datalist
        }
      })
    },
    //申请弹窗
    kaipiao(){
      if(this.selectmsg == ''){
        this.$message('请先选择需要开票的申请')
        return
      }
      this.show = true
    },
    //确认申请
    application(){
		console.log("1111")
		
	
      if(this.billingcontent == ''){
        this.$message('请输入开票内容')
        return
      }
      if(this.postalCode == ''){
        this.$message('请输入邮寄编码')
        return
      }
      if(this.inpdisabled == false){
        this.$message('请确认完成修改')
        return
      }
	  

	  
      var params = {
        id:this.id,
        invoiceAmount:this.selectmsg.masterPrice,
        invoiceIssuingEntity:this.msgs.invoiceTitle,
        contentOfMakeOutAnInvoice:this.billingcontent,
        recipient:this.selectmsg.contact,
        recipientContactNumber:this.selectmsg.contactPhoneNumber,
        mailingAddress:this.selectmsg.constructionaddress,
        postalCode:this.postalCode,
        createEnterpriseCustomersId:this.selectmsg.publisherId,
        malfunctionIds:this.selectmsg.id,
      }
      ConfirmMakeOutAnInvoice(params).then(res=>{
        console.log(res)
        this.$message(res.data.msg)
        if(res.data.code == 200){
          this.show = false
          this.currentPage = 1
          this.getapplymsg()
          this.pageshow = false;//让分页隐藏
            this.$nextTick(() => {//重新渲染分页
                this.pageshow = true;
            });
        }
      })
    },
    //选择人员开票
    handleSelectionChange(rows){
      this.selectmsg = ''
      this.postalCode = '' 
      this.billingcontent = ''
      if (rows.length > 1) {
        var newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.multipleTable.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable.toggleRowSelection(it, false);
            return false;
          }
        });
        this.selectmsg = newRows[0];
      } else if(rows.length == 1){
        this.selectmsg = rows[0];
      }else{
        this.selectmsg = ''
      }
    },
    //切换分页
    handleCurrentChange(val) {
      this.multipleSelection = []
      var params = {
        pageNum:val,
        pageSize:11,
        enterpriseCustomersId:this.id
      }
      UninvoicedWorkOrder(params).then(res=>{
        console.log(res)
        if(res.data.code == 200){
          this.totalnum = res.data.count
          var datalist = res.data.data
          for(var i=0;i<datalist.length;i++){
            datalist[i].constructionaddress = datalist[i].provinces+datalist[i].city+datalist[i].area+datalist[i].address
          }
          this.tableData = datalist
        }
      })
    },
  },
};
</script>

<style scoped>
.apply {
  padding: 34px 14px 0px;
}
.apply_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top_left {
  display: flex;
  align-items: center;
}
.top_list_one {
  display: flex;
  align-items: center;
  margin-right: 38px;
}
.top_list_one div {
  margin-right: 13px;
  font-size: 14px;
}
.top_right {
  display: flex;
}
.top_right div:first-child {
  cursor: pointer;
  width: 80px;
  height: 38px;
  font-size: 14px;
  background-color: #4a9bec;
  color: #ffffff;
  text-align: center;
  line-height: 38px;
}
.top_right div:last-child {
  cursor: pointer;
  margin-left: 10px;
  width: 80px;
  height: 38px;
  font-size: 14px;
  background-color: #26ad72;
  color: #ffffff;
  text-align: center;
  line-height: 38px;
}
.cen_list {
  margin-top: 40px;
}
.dialog_con{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dialog_con_list{
    line-height: 38px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dialog_con_list div{
    width: 48%;
}
.dialog_left{
    font-size: 14px;
    text-align: right;
}
.dialog_right{
    font-size: 14px;
    text-align: left;
}
.dialog_right_inp{
    background-color: #FFFFFF;
}
.mar_top{
    margin-top: 20px;
}
.adit{
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    color: #4A9BEC;
}
.shegnqing{
    cursor: pointer;
    margin: 30px auto 0px;
    width: 97px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    color: #FFFFFF;
    background-color: #4A9BEC;
}
.fenye{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
div /deep/.el-table__header-wrapper  .el-checkbox{
	display:none
}
</style>