<template>
  <div class="myself">
      <!-- 个人资料 -->
      <div class="myself_list">
          <div class="self_list">
              <div class="self_list_one">个人资料</div>
              <div class="self_list_two" v-show="inpdisabled==true" @click="inpdisabled=false">编辑</div>
              <div class="self_list_two" v-show="inpdisabled==false" @click="editdata">完成</div>
          </div>
          <div class="self_con">
              <el-upload
                class="avatar-uploader"
                :action="imgBaseUrl+'/app/uploadFileToData'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :multiple="false"
                list-type="picture"
                :disabled="inpdisabled">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="self_con_list">
                  <div>你的名字：</div>
                  <input type="text" :disabled="inpdisabled" placeholder="请输入你的名字" v-model="msg.contactPersonnel">
              </div>
              <div class="self_con_list">
                  <div>手机号码：</div>
                  <input type="text" :disabled="inpdisabled" placeholder="请输入手机号码" v-model="msg.contactPersonnelPhone">
              </div>
              <div class="self_con_list"> 
                  <div>邮政编码：</div>
                  <input type="text" :disabled="inpdisabled" placeholder="请输入邮政编码" v-model="msg.postcode">
              </div>
              <div class="self_con_list">
                  <div>居住地址：</div>
                  <input style="width:300px" type="text" :disabled="inpdisabled" placeholder="请输入居住地址" v-model="msg.personalAddress">
              </div>
          </div>
      </div>
      <!-- 个人资料 -->
      <!-- 邮寄地址 -->
      <!-- <div class="myself_list">
          <div class="self_list" @click="mail=!mail">
              <div class="self_list_one">邮寄地址</div>
              <img class="self_list_img" v-show="mail==false" src="../../../assets/home/icon-up.png" alt="">
              <img class="self_list_img" v-show="mail==true" src="../../../assets/home/icon-down.png" alt="">
          </div>
          <div class="self_con adress_list" v-show="mail==1">
              <div class="self_con_listone">
                  <div>邮寄地址：</div>
                  <input style="width:300px" type="text" :disabled="aditshow" v-model="adress" placeholder="请输入邮寄地址">
              </div>
              <div class="adit" v-show="aditshow==true" @click="aditadress">编辑</div>
              <div class="adit" v-show="aditshow==false">完成</div> 
          </div>
      </div> -->
      <!-- 邮寄地址 --> 
        <!-- 用户协议 -->
      <div class="myself_list">
          <div class="self_list" @click="agreement=!agreement">
              <div class="self_list_one">用户协议</div>
              <img class="self_list_img" v-show="agreement==false" src="../../../assets/home/icon-up.png" alt="">
              <img class="self_list_img" v-show="agreement==true" src="../../../assets/home/icon-down.png" alt="">
          </div>
          <div class="self_con" v-show="agreement==1" style="width: 60%;">
              {{detail}}
          </div>
      </div>
      <!-- 用户协议 -->
      <!-- 客户电话 -->
      <div class="myself_list">
          <div class="self_list" @click="customer=!customer">
              <div class="self_list_one">客服电话</div>
              <img class="self_list_img" v-show="customer==false" src="../../../assets/home/icon-up.png" alt="">
              <img class="self_list_img" v-show="customer==true" src="../../../assets/home/icon-down.png" alt="">
          </div>
          <div class="self_con" v-show="customer==1">
              <div class="kehuphone">客服电话:{{phone}}</div>
          </div>
      </div>
      <!-- 修改密码 -->
		<div class="myself_list">
			<div class="self_list" @click="changePassword=!changePassword">
				<div class="self_list_one">修改密码</div>
				<img class="self_list_img" v-show="changePassword==false" src="../../../assets/home/icon-up.png" alt="">
				<img class="self_list_img" v-show="changePassword==true" src="../../../assets/home/icon-down.png" alt="">
			</div>
			<div class="self_con" v-show="changePassword==1">
				<div class="self_con_list">
				    <div style="width: 10%;">登录账号：</div>
				    <input type="text" disabled="" v-model="msg.phone" placeholder="请输入新密码">
				</div>
				<div class="self_con_list">
				    <div style="width: 10%;">原密码：</div>
				    <input type="password" v-model="Y" placeholder="请输入原密码">
				</div>
				<div class="self_con_list">
				    <div style="width: 10%;">新密码：</div>
				    <input type="password" v-model="X" placeholder="请输入新密码">
				</div>
				<div class="self_con_list">
				    <div style="width: 10%;">请再次输入新密码：</div>
				    <input type="password" v-model="NX" placeholder="请再次输入新密码">
				</div>
				
				<div class="self_con_list_1">
					<div class="self_con_list_1_1" @click="changePasswordBtn()">确定</div>
				</div>
				
			</div>
		</div>
	  
	  
  </div>
</template>

<script>
import { EditEnterpriseCustomersById,GetCustomerService,xieyi,editPassword} from '../../../api/request';
import eventBus from '../../../api/goBackEntity.js';
import {imghost} from '../../../api/common';
export default {
name:'Myself',
data(){
    return{
        id:'',//企业id
        msg:'',//个人信息
        imageUrl: '',
        imgBaseUrl:imghost,
        inpdisabled:true,//控制个人资料的可编辑性
        aditshow:true,//控制邮寄地址的可编辑性
        adress:'山东省青岛市黄岛区洪城小区1226',//绑定邮寄地址
        mail:false,//邮寄地址的显示和隐藏
        agreement:false,//用户协议的显示和隐藏
        customer:false,//客户电话的显示和隐藏
		changePassword:false, //修改密码的显示和隐藏
        phone:'',
		detail:"",
		
		Y:"", //原密码
		X:"", //新密码
		NX:"", //再次密码
		
    }
},
created(){
    if(localStorage.getItem("msg") || localStorage.getItem("id")){
      this.msg = JSON.parse(localStorage.getItem("msg"))
      if(this.msg.headPic !=null && this.msg.headPic !=''){
          this.imageUrl = this.imgBaseUrl+this.msg.headPic
      }
      this.id = Number(localStorage.getItem("id"))
      console.log(this.msg)   
    }
    this.getphone();
	this.getAgreement()
},
methods:{
    handleAvatarSuccess(res, file) {
        console.log(res)
        var image = res
        this.imageUrl = URL.createObjectURL(file.raw);
        var params = {
            id:this.id,
            headPic:image
        }
        EditEnterpriseCustomersById(params).then(res=>{
            this.$message(res.data.msg)
            if(res.data.code == 200){
                this.msg.headPic = image
                localStorage.setItem("msg",JSON.stringify(this.msg))
            }
        })
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.type === 'image/png';
        if (isJPG==false && isLt2M==false) {
          this.$message.error('上传文件需为图片');
        }
        return isJPG || isLt2M;
    },
    //编辑个人资料
    editdata(){
        if(this.msg.contactPersonnel==null || this.msg.contactPersonnel==''){
            this.$message('请输入你的名字')
            return
        }
        if(this.msg.contactPersonnelPhone==null || this.msg.contactPersonnelPhone==''){
            this.$message('请输入手机号码')
            return
        }
        if(this.msg.postcode==null || this.msg.postcode==''){
            this.$message('请输入邮政编码')
            return
        }
        if(this.msg.personalAddress==null || this.msg.personalAddress==''){
            this.$message('请输入居住地址')
            return
        }
        var params = {
            id:this.id,
            contactPersonnel:this.msg.contactPersonnel,
            contactPersonnelPhone:this.msg.contactPersonnelPhone,
            postcode:this.msg.postcode,
            personalAddress:this.msg.personalAddress
        }
        EditEnterpriseCustomersById(params).then(res=>{
            console.log(res)
            this.$message(res.data.msg)
            if(res.data.code == 200){
                this.inpdisabled = true
                localStorage.setItem("msg",JSON.stringify(this.msg))
                eventBus.$emit('mm',1);
            }
        })
        // console.log(params)
    },
	
	getAgreement(){//获取pc协议
		var params = {}
		xieyi(params).then(res=>{
		    console.log(res)
		    if(res.data.code == 200){
		        this.detail = res.data.data.detail
		    }
		})
	},
	
    getphone(){
        var params = {
			type:3
		}
        GetCustomerService(params).then(res=>{
            console.log(res)
            if(res.data.code == 200){
                this.phone = res.data.data[0].phone
            }
        })
    },
    aditadress(){
        this.aditshow=!this.aditshow
        this.adress = ''
    },
	
	
	changePasswordBtn(){ //修改密码
		console.log("修改密码");
		if(this.Y==""){
		    this.$message('请输入原密码')
		    return
		}
		if(this.X==""){
		    this.$message('请输入新密码')
		    return
		}
		if(this.NX==""){
		    this.$message('请再次输入新密码')
		    return
		}
		var params = {
			userId:this.id,
			oldPassword:this.Y,
			newPassword:this.X,
			confirmPassword:this.NX,
		}
		editPassword(params).then(res=>{
		    console.log(res)
		    if(res.data.code == 200){
		       this.$message('修改成功')
		    }else{
				this.$message(res.data.data)
			}
		})
	}
	
	
	
}
}
</script>

<style scoped>
.myself{
    padding-top: 16px;
    padding-left: 30px;
}
.myself_list{
    margin-bottom: 30px;
}
.self_list{
    width: 890px;
    height: 60px;
    box-sizing: border-box;
    background-color: #F5F5F5;
    display: flex;
    padding: 0px 40px;
    justify-content: space-between;
    align-items: center;
}
.self_list_one{
    font-size: 18px;
}
.self_list_two{
    font-size: 18px;
    color:#5D95F6;
    cursor: pointer;
}
.self_list_img{
    width: 16px;
    height: 16px;
}
.self_con{
    padding-left: 40px;
}
.touxiang{
    margin-top: 26px;
    width: 126px;
    height: 126px;
    border-radius: 10px;
    background-color: red;
}
.self_con_list{
    margin-top: 30px;
    display: flex;
    align-items: center;
}
.self_con_list div{
    font-size: 16px;
}
.self_con_list input{
    margin-left: 14px;
    font-size: 16px;
    width: 200px;
    height: 40px;
    box-sizing: border-box;
    padding-left: 10px;
    /* background-color: red; */
    border-radius: 4px;
    border:1px solid #CCCCCC;
}
.self_con_listone{
    margin-top: 30px;
    display: flex;
    align-items: center;
}
.self_con_listone div{
    font-size: 16px;
}
.self_con_listone input{
    margin-left: 14px;
    font-size: 16px;
    width: 200px;
    height: 30px;
    box-sizing: border-box;
    padding-left: 10px;
    background-color: #FFFFFF;
}
.kehuphone{
    margin-top: 30px;
    font-size: 14px;
    color: #5D95F6;
}
.adress_list{
    width: 890px;
    box-sizing: border-box;
    padding-right: 55px;
    display: flex;
    justify-content: space-between;
}
.adit{
    cursor: pointer;
    margin-top: 30px;
    font-size: 16px;
    color: #5D95F6;
}
.avatar-uploader{
    margin-top: 26px;
    width: 126px;
    height: 126px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload {
    border: 1px solid #cccccc;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 126px;
    height: 126px;
    line-height: 126px;
    text-align: center;
}
.avatar {
    width: 126px;
    height: 126px;
    display: block;
}

.self_con_list_1{
	width: 100%;
	height: 150px;
	/* background: #02AAEF; */
	display: flex;
	align-items: center;
	
}
.self_con_list_1_1{
	width: 140px;
	height: 50px;
	background: #2C9EF4;
	margin-left: 5%;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	color: #FFFFFF;
}
</style>